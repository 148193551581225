
@import url('https://fonts.googleapis.com/css?family=Inter:100,200,300,400,500,600,700,800,900&display=swap');

 

  
/*--------------------------
  03.Scroll bar
  ---------------------*/

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #525965;
  border: 0px none #ffffff;
  border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
  background: #525965;
}

::-webkit-scrollbar-thumb:active {
  background: #525965;
}

::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: transparent;
}

::-webkit-scrollbar-track:active {
  background: transparent;
}

::-webkit-scrollbar-corner {
  background: transparent;
}


/*--------------------------------
  04.General
  --------------------------------*/

html,body
{
  height: 100%;
}

body {
  font-size: 14px!important;
  font-family: 'Inter', sans-serif;
}

.page-wrapper .sidebar-wrapper,
.sidebar-wrapper .sidebar-brand>a,
.sidebar-wrapper .sidebar-dropdown>a:after,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
.sidebar-wrapper ul li a i,
.page-wrapper .page-content,
.sidebar-wrapper .sidebar-search input.search-menu,
.sidebar-wrapper .sidebar-search .input-group-text,
.sidebar-wrapper .sidebar-menu ul li a,
#show-sidebar,
#close-sidebar {
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  -ms-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.page-wrapper .page-content {
  display: inline-block;

  padding-left: 0px;
}

.page-wrapper .page-content div.main-content2 {
  padding: 20px 40px;
}

pre {
  display: block;
  font-size: 1em;
  color: #212529;
  border: 1px solid #ccc;
  width: 70%;
  border-radius: 6px;
  margin: 30px 0;
  padding: 15px;
}

ul.pro-ul {
  list-style: none;
  padding-left: 30px;
}

ul.pro-ul li {
  position: relative;
  padding-left: 20px;
}

ul.pro-ul li:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  display: block;
  border: 1px solid #f37a61;
  border-radius: 50%;
  left: 0;
  top: 8px;
}

ul.pro-ul li p {
  margin-bottom: 8px;
}

.section {
  padding: 60px 0;
  border-top: 1px solid #f0f0f0;
}

.form-control:focus {
  color: #222;
  background-color: transparent !important;
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.border-no-sec {
  padding-top: 0px;
  border-top: 0px;
}

.blockquote p {
  font-size: unset;
}

input:focus,
textarea:focus {
  outline:none;
  border:none;
}

.search-wrapper input:focus,
.search-wrapper textarea:focus {
  outline: none;
  border: 0px !important;
}

.preloader
{
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}

/*end of general*/


/*--------------------------------
  05.Typography
  --------------------------------*/

/*h1,
h2,
h3,
h4 {
  color: #434343;
}

h1 {
  font-size: 3.2em;
}

h2 {
  font-size: 2.8em;
}*/

p {
  font-size: 15px;
  color: #757575;
  font-weight: 300;
}

a:hover,
a:focus {
  text-decoration: none;
  cursor: pointer;
}


/*end of typography*/


/*-----------------------------
  06.Header
  ----------------------------*/

.header .nav2 {
 
  background: none;
  z-index: 9;
  position: unset;
}

.header .navbar-collapse ul.navbar-nav {
  width: 100%;
}

.header li a.doc-action {
  font-weight: 600;
  color: #fff !important;
}

.upper-head {
  padding: 20px 55px;
}

.upper-head h2 {
  margin: 30px 0 50px;
}

.upper-head h3 {
  margin-bottom: 20px;
}

.upper-head p {
  width: 90%;
  color: #222;
}

.header-full .search-wrapper {
  padding: 0 10px;
  border-radius: 6px;
  border-bottom: 0px;
}

.header-full.new-index-header {
  background: none;
  position: absolute;
  width: 100%;
  z-index: 10;
}

.header-full.new-index-header a.brand-name {
  color: #fff;
}

.header-full.new-index-header .search-wrapper {
  background: rgba(255, 255, 255, 0.3);
  padding: 0 10px;
  border-radius: 2px;
  border-bottom: 0px;
}

.search-wrapper input {
  border: 0px;
  background: none;
  padding: 0 3px;
  height: 38px;
}

.search-wrapper button {
  border: 0px;
}

.search-wrapper button i {
  font-size: 1em;
}

.search-wrapper button {
  background: none;
}

.search-wrapper button:focus {
  border: 0px;
  box-shadow: none;
}

.doc-head-section .navbar-nav .dropdown-menu {
  border: 1px solid #e4e4e4;
  border-radius: 0;
  margin-top: 10px;
}

.doc-head-section .navbar-nav .dropdown-menu a {
  font-size: 1em;
}

.doc-head-section .navbar-nav .dropdown-menu .dropdown-item {
  font-size: 0.9em;
}

.doc-head-section .navbar-nav .dropdown-menu .dropdown-item.active{
  background: #c7d3fb !important;
}

.header .nav2 .article-head {
  padding: .5rem 1rem;
}

.header.header-full .nav2 {
  padding: 10px 0px;
}

.header .nav2 .navbar-collapse li.nav-item>a:first-child {
  padding-left: 0px;
}

.header .nav2 .navbar-collapse li.nav-item>a {
    padding-right: 16px;
    font-size: 14px;
    font-weight: 500;
    color: #6f7f9f;
}

.header .nav2 .navbar-collapse li.nav-item>a.dropdown-toggle::after {
  font-size: 0.71em;
}

.brand-name {
  font-size: 1em;
  font-weight: 800;
  float: left;
  text-transform: uppercase;
}

.navbar-brand:hover
{
  color: #fff;
}

.white-name {
  color: #fff;
}

.nav2 .light-navbar ul>li>a {
  color: #fff;
}

.org-theme.bootstrap-color .nav2 .light-navbar .search-wrapper {
  border-bottom: 1px solid #fff;
}

.org-theme.bootstrap-color .nav2 .light-navbar .search-wrapper input {
  color: #fff;
}

.org-theme.bootstrap-color .nav2 .light-navbar .search-wrapper i {
  color: #fff;
}

.dropdown-item.active,
.dropdown-item:active {
  background: #e0e0e0 !important;
  color: #000;
}

/*end of header*/


/*-------------------------------
  07.Footer
  -------------------------------*/

footer {
  text-align: right;
  padding: 12px 30px;
}

footer ul {
  padding: 0px;
  margin: 0px;
}

footer ul li {
  display: inline-block;
  text-align: right;
}

footer ul li a {
  color: #4468d8;
  font-size: 1.07em;
  padding: 0 12px;
  font-weight: 300;
  border-right: 1px solid #bacbff;
}

footer ul li:last-child a {
  border-right: 0px;
}


/*end of footer*/


/*--------------------------------
  08.Slidenav
  --------------------------------*/

.nav2 {
  position: sticky;
  width: 100%;
  z-index: 9;
  padding-left: 0px;
  top: 0;
  width: 100%;
}

.nav2 a {
  padding: 7px 20px;
  color: #7f7f7f;
  font-weight: 400;
  display: block;
  font-size: 1em;
}

.nav2 a:hover {
  background: rgba(255, 255, 255, 0.7);
}

.fork-wr img {
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  border: 0;
}

.with-sidebar .brand-name {
  display: none;
}


/*-------------
  08.1 toggeled sidebar
  ---------------*/

.page-wrapper.toggled .sidebar-wrapper {
  left: 0px;
}


/*-----------
  08.2 show sidebar button
  -------------*/

#show-sidebar {
  position: fixed;
  left: 0;
  top: 38px;
  border-radius: 0 4px 4px 0px;
  width: 45px;
  transition-delay: 0.3s;
}
#show-sidebar i{
  color: #fff;
}

.page-wrapper.toggled #show-sidebar {
  left: -40px;
}


/*-----------
  08.3 sidebar-wrapper
  ------------*/

.sidebar-wrapper {
  width: 300px;
  height: 100%;
  max-height: 100%;
  position: fixed;
  top: 0;
  left: -300px;
  z-index: 999;
  overflow: scroll;
  scrollbar-width: none;
}

.sidebar-wrapper ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  height: auto;
}

.sidebar-wrapper a {
  text-decoration: none;
}


/*-----------
  08.4 sidebar-brand
  ------------*/

.sidebar-wrapper .sidebar-brand {
  padding: 16px 16px 17px 27px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.sidebar-wrapper .sidebar-brand>a {
  text-transform: uppercase;
  font-weight: bold;
  flex-grow: 1;
}

.sidebar-wrapper .sidebar-brand #close-sidebar {
  cursor: pointer;
  font-size: 1em;
}


/*-------------
  08.5 sidebar-header
  --------------*/

.sidebar-wrapper .sidebar-header .user-info>span {
  display: block;
}

.sidebar-wrapper .sidebar-header .user-info .user-role {
  font-size: 0.85em;
}

.sidebar-wrapper .sidebar-header .user-info .user-status {
  font-size: 0.78em;
  margin-top: 4px;
}

.sidebar-wrapper .sidebar-header .user-info .user-status i {
  font-size: 0.571em;
  margin-right: 4px;
  color: #5cb85c;
}


/*------------
  08.6 sidebar-search
  ------------*/

.sidebar-wrapper .sidebar-search>div {
  padding: 10px 20px;
}


/*------------
  08.7 sidebar-menu
  ------------*/

.sidebar-wrapper .sidebar-menu {
  padding-bottom: 10px;
}

.sidebar-wrapper .sidebar-menu .header-menu span {
  font-weight: bold;
  font-size: 1em;
  padding: 15px 20px 5px 20px;
  display: inline-block;
}

.sidebar-wrapper .sidebar-menu ul li a {
  display: inline-block;
  width: 90%;
  text-decoration: none;
  position: relative;
  padding: 8px 0px 8px 27px;
  position: relative;
}
.sidebar-wrapper .sidebar-menu ul li.side-list>a:before,
.sidebar-wrapper .sidebar-menu ul li.sidebar-dropdown>a:before {
  position: absolute;
  content: "";
  left: 26px;
  font-size: 1.92em;
  top: -2px;
}

.sidebar-wrapper .sidebar-menu ul li a i {
  margin-right: 10px;
  font-size: 0.85em;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 4px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown a span{color: yellow;} 


.sidebar-wrapper .sidebar-menu ul li a:hover>i::before {
  display: inline-block;
  animation: swing ease-in-out 0.5s 1 alternate;
}

#navbarSupportedContent{padding: 11px 16px 11px 16px;background-color:rgb(75, 156, 94);}


.sidebar-wrapper .sidebar-menu .sidebar-dropdown>a:after {
  font-family: 'themify';
  /*content: "⏺";*/
  content: " ";
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: 0 0;
  position: absolute;
  right: 15px;
  top: 13px;
  font-size: 0.57em;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul {
  padding: 5px 0;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li {
  padding-left: 0px;
  font-size: 0.92em;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before {
  content: "-";
  font-family: 'themify';
  display: inline-block;
  text-align: center;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 10px;
  font-size: 0.57em;
}

.sidebar-wrapper .sidebar-menu ul li a span.label,
.sidebar-wrapper .sidebar-menu ul li a span.badge {
  float: right;
  margin-top: 8px;
  margin-left: 5px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .badge,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .label {
  float: right;
  margin-top: 0px;
}

.sidebar-wrapper .sidebar-menu .sidebar-submenu {
  display: block;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown.active>a:after {
  transform: rotate(90deg);
  right: 17px;
}

.sidebar-brand a {
  letter-spacing: 2px
}

.content-section {
  width: 100%;
  float: left;
  padding-left: 0px;
  padding-top: 10px;
}
.content-section img{
  width: 100%;
}
.right-nav {
  width: 25%;
  float: left;
  padding-left: 25px;
  margin-left: 5%;
  border-left: 1px solid #ccc;
}


/*----------------
  08.8 color theme
  -------------*/

.org-theme .sidebar-wrapper .sidebar-search input.search-menu,
.org-theme .sidebar-wrapper .sidebar-search .input-group-text {
  border-color: transparent;
  box-shadow: none;
}

.org-theme .sidebar-wrapper .sidebar-header .user-info .user-role,
.org-theme .sidebar-wrapper .sidebar-header .user-info .user-status,
.org-theme .sidebar-wrapper .sidebar-search input.search-menu,
.org-theme .sidebar-wrapper .sidebar-search .input-group-text,
.org-theme .sidebar-wrapper .sidebar-brand>a,
.org-theme .sidebar-wrapper .sidebar-menu ul li a,
.org-theme .sidebar-footer>a {
  color: #fff;
  font-size: 1.075em;
}

.org-theme .sidebar-wrapper .sidebar-menu li>a:hover{color: #000;}

.org-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active>a,
.org-theme .sidebar-wrapper .sidebar-header .user-info,
.org-theme .sidebar-wrapper .sidebar-brand>a:hover,
.org-theme .sidebar-footer>a:hover i {
  color: #fff;
}

.page-wrapper.org-theme.toggled #close-sidebar {
  color: #ffffff;
}

.page-wrapper.org-theme.toggled #close-sidebar:hover {
  color: #ffffff;
}

.org-theme .sidebar-wrapper ul li:hover a i,
.org-theme .sidebar-wrapper .sidebar-dropdown .sidebar-submenu li a:hover:before,
.org-theme .sidebar-wrapper .sidebar-search input.search-menu:focus+span,
.org-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active a i {
  color: #fff;
}

.org-theme.sidebar-wrapper .sidebar-menu .header-menu span {
  color: #6c7b88;
}

.org-theme .sidebar-footer {
  background: #3a3f48;
  box-shadow: 0px -1px 5px #282c33;
  border-top: 1px solid #464a52;
}

.org-theme .sidebar-footer>a:first-child {
  border-left: none;
}

.org-theme .sidebar-footer>a:last-child {
  border-right: none;
}


/*end of sidenav*/


/*--------------------------------
  0.9 Index
  -------------------------------*/

.banner {
  background:linear-gradient(-20deg, rgb(0, 0, 0) 0%, rgb(5, 22, 80) 100%);
  width: 100%;
  position: relative;
}

.banner::after {
  content: "";

  opacity: 0.7;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
}

.banner-input {
  text-align: center;
  padding: 150px 15px 200px;
  z-index: 9;
  position: relative;
}

.banner-input h3 {
  color: #fff;
}

.banner-input input {
  width: 100%;
  max-width: 700px;
  height: 50px;
  border-radius: 5px;
  border: 0px;
  background: rgba(150, 150, 150, 0.2);
  padding: 0 30px;
  color: #fff;
  margin-top: 10px;
}

.banner-input input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
}

.banner-input input::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}

.banner-input input:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
}

.banner-input input:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}

.boxes-banner {
  padding:0px 0 100px;
}

.box-head-in {
  text-align: center;
  padding-bottom: 30px;
}

.box-wrapper {
  width: 100%;
  background:linear-gradient(-20deg, rgba(9,46,168,1) 0%, rgba(15,37,110,1) 100%);
  position: relative;
  margin-top: -100px;
  height: 180px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #0e287f;
}
.box-wrapper h3
{
  font-size: 18px;
  color: #fff;
}
.box-wrapper p
{
  font-size: 14px;
  padding-top: 8px;
  color: #e4e4e4;
}

.box-wrapper a.btn {
  border-radius: 2px;
  width: 200px;
  text-align: left;
  color: #7998ff;
  transition: all .3s ease-in-out;
  font-size: 1em;
  padding: 0px;
  position: absolute;
  bottom: 18px;
}

.box-wrapper:hover a.btn {
  color: #9cb5ee;
  transition: all .3s ease-in-out;
}

.main-articles ul {
  padding: 0px;
}

.main-articles ul li {
  width: calc(100% / 5);
  float: left;
  padding: 16px;
  list-style: none;
}

.main-articles ul li a {
  width: 100%;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 0px 0px 17px 0px #e4e4e4;
}

.main-articles ul li a i {
  font-size: 42px;
  color: #98aae6;
  margin-bottom: 17px;
  display: block;
}

.main-articles ul li a p {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 13px;
  text-transform: capitalize;
}

.team-img-outer-wrapper {
  margin-top: 50px;
}

.team-img-outer-wrapper ul {
  padding: 0px;
  margin: 0px;
  text-align: center;
}

.team-img-outer-wrapper ul li {
  display: inline-block;
  padding: 0 20px
}

.team-img-wrapper {
  display: block;
  height: 75px;
  width: 75px;
  border-radius: 50%;
  background: #ccc;
  margin: 0 auto;
  overflow: hidden;
}

.team-img-outer-wrapper ul li h4 {
  font-size: 1.2em;
  margin: 5px 0 0;
}

.team-img-outer-wrapper ul li p {
  font-size: .9em;
}

.reach-us {
  text-align: center;
  padding: 100px 0;
  border: 1px solid #d9e1fa;
  margin-top: 100px;
}


/*end of index*/


/*--------------------------------
  10. Introduction
  -------------------------------*/

.introduction {
  padding-bottom: 50px;
}

.introduction p {
  font-size: 1.4em;
  color: #414141;
}

#why_build ul {
  margin-top: 25px;
}


/*end of introduction*/


/*--------------------------------
  11. Layout
  -------------------------------*/

p.feature-para {
  font-size: 1em;
}

#features h4 i {
  font-size: 1.28em;
  margin-right: 12px;
}

.feature-inner-wrapper {
  margin-top: 30px;
}

.imp-note {
  border: 1px solid #e5e5e5;
  padding: 15px 20px;
  margin-bottom: 20px;
}


/*end of layout*/


/*--------------------------------
    12. Alert
    --------------------------------*/

.alert-primary {
  background: rgba(204, 229, 255, 1);
  background: -moz-linear-gradient(left, rgba(204, 229, 255, 1) 0%, rgba(115, 172, 230, 1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(204, 229, 255, 1)), color-stop(100%, rgba(115, 172, 230, 1)));
  background: -webkit-linear-gradient(left, rgba(204, 229, 255, 1) 0%, rgba(115, 172, 230, 1) 100%);
  background: -o-linear-gradient(left, rgba(204, 229, 255, 1) 0%, rgba(115, 172, 230, 1) 100%);
  background: -ms-linear-gradient(left, rgba(204, 229, 255, 1) 0%, rgba(115, 172, 230, 1) 100%);
  background: linear-gradient(to right, rgba(204, 229, 255, 1) 0%, rgba(115, 172, 230, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cce5ff', endColorstr='#73ace6', GradientType=1);
}

.alert-secondary {
  background: rgba(226, 227, 229, 1);
  background: -moz-linear-gradient(left, rgba(226, 227, 229, 1) 0%, rgba(186, 186, 186, 1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(226, 227, 229, 1)), color-stop(100%, rgba(186, 186, 186, 1)));
  background: -webkit-linear-gradient(left, rgba(226, 227, 229, 1) 0%, rgba(186, 186, 186, 1) 100%);
  background: -o-linear-gradient(left, rgba(226, 227, 229, 1) 0%, rgba(186, 186, 186, 1) 100%);
  background: -ms-linear-gradient(left, rgba(226, 227, 229, 1) 0%, rgba(186, 186, 186, 1) 100%);
  background: linear-gradient(to right, rgba(226, 227, 229, 1) 0%, rgba(186, 186, 186, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2e3e5', endColorstr='#bababa', GradientType=1);
}

.alert-success {
  background: rgba(212, 237, 218, 1);
  background: -moz-linear-gradient(left, rgba(212, 237, 218, 1) 0%, rgba(93, 240, 127, 1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(212, 237, 218, 1)), color-stop(100%, rgba(93, 240, 127, 1)));
  background: -webkit-linear-gradient(left, rgba(212, 237, 218, 1) 0%, rgba(93, 240, 127, 1) 100%);
  background: -o-linear-gradient(left, rgba(212, 237, 218, 1) 0%, rgba(93, 240, 127, 1) 100%);
  background: -ms-linear-gradient(left, rgba(212, 237, 218, 1) 0%, rgba(93, 240, 127, 1) 100%);
  background: linear-gradient(to right, rgba(212, 237, 218, 1) 0%, rgba(93, 240, 127, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d4edda', endColorstr='#5df07f', GradientType=1);
}

.alert-danger {
  background: rgba(248, 215, 218, 1);
  background: -moz-linear-gradient(left, rgba(248, 215, 218, 1) 0%, rgba(252, 154, 164, 1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(248, 215, 218, 1)), color-stop(100%, rgba(252, 154, 164, 1)));
  background: -webkit-linear-gradient(left, rgba(248, 215, 218, 1) 0%, rgba(252, 154, 164, 1) 100%);
  background: -o-linear-gradient(left, rgba(248, 215, 218, 1) 0%, rgba(252, 154, 164, 1) 100%);
  background: -ms-linear-gradient(left, rgba(248, 215, 218, 1) 0%, rgba(252, 154, 164, 1) 100%);
  background: linear-gradient(to right, rgba(248, 215, 218, 1) 0%, rgba(252, 154, 164, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8d7da', endColorstr='#fc9aa4', GradientType=1);
}

.alert-warning {
  background: rgba(255, 243, 205, 1);
  background: -moz-linear-gradient(left, rgba(255, 243, 205, 1) 0%, rgba(219, 190, 103, 1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255, 243, 205, 1)), color-stop(100%, rgba(219, 190, 103, 1)));
  background: -webkit-linear-gradient(left, rgba(255, 243, 205, 1) 0%, rgba(219, 190, 103, 1) 100%);
  background: -o-linear-gradient(left, rgba(255, 243, 205, 1) 0%, rgba(219, 190, 103, 1) 100%);
  background: -ms-linear-gradient(left, rgba(255, 243, 205, 1) 0%, rgba(219, 190, 103, 1) 100%);
  background: linear-gradient(to right, rgba(255, 243, 205, 1) 0%, rgba(219, 190, 103, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff3cd', endColorstr='#dbbe67', GradientType=1);
}

.alert-info {
  background: rgba(209, 236, 241, 1);
  background: -moz-linear-gradient(left, rgba(209, 236, 241, 1) 0%, rgba(98, 192, 209, 1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(209, 236, 241, 1)), color-stop(100%, rgba(98, 192, 209, 1)));
  background: -webkit-linear-gradient(left, rgba(209, 236, 241, 1) 0%, rgba(98, 192, 209, 1) 100%);
  background: -o-linear-gradient(left, rgba(209, 236, 241, 1) 0%, rgba(98, 192, 209, 1) 100%);
  background: -ms-linear-gradient(left, rgba(209, 236, 241, 1) 0%, rgba(98, 192, 209, 1) 100%);
  background: linear-gradient(to right, rgba(209, 236, 241, 1) 0%, rgba(98, 192, 209, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d1ecf1', endColorstr='#62c0d1', GradientType=1);
}


/*end of alert*/


/*--------------------------------
    13. Bootstrap
    --------------------------------*/


/*------
      13.1 sidenav
      ----------*/

.relative-sidenav {
  position: relative;
  width: 100%;
  background: #fff !important;
  border-right: 1px solid #dbcef0;
  padding: 35px 10px 0 0;
}

.doc-head-section.set-2-doc-head {
  background: #563d7c !important;
}

.set-2-doc-head .upper-head {
  padding: 10px 0px 45px !important;
}

.set-2-doc-head .upper-head p,
.set-2-doc-head .upper-head h2 {
  color: #fff !important;
}

.set-2-doc-head .header nav {
  padding: 10px 0;
}

.org-theme.bootstrap-color .set-2-doc-head .start-btn {
  background: #9f7bd5;
  font-size: 0.85em;
  color: #fff;
}

.org-theme.bootstrap-color .set-2-doc-head .download-btn {
  border: 1px solid #9f7bd5;
  color: #ccb3f2;
}

#navbarSupportedContent .navbar-nav a:hover {color: yellow}

/*end of sidenav*/


/*--------
      13.2 main-content
      --------*/

.set-2-page-content {
  padding-left: 0px !important;
}

.set-2-page-content .main-content2 {
  padding: 20px 0px !important;
}

.org-theme.bootstrap-color .sidebar-wrapper.relative-sidenav .sidebar-menu ul li a {
  color: #313030;
  font-size: 1.075em;
  font-weight: 300;
}

.right-min-nav nav a {
  letter-spacing: 0;
  color: #605f5f;
  font-weight: 300;
}
.header.bootstrap-head ul.navbar-nav > li > a
{
  color: #dadada;
}
.header.bootstrap-head-min ul.navbar-nav > li > a
{
  color: #222;
}

/*end of main-content*/


/*-----------
      13.3 footer
      -----------*/

.footer-min {
  background: #ffffff;
  border-top: 1px solid #dbcef0;
}

footer.footer-min ul li a {
  font-weight: 400;
  color: #797979;
}

/*sematic footer*/

footer.semantic-footer{
  background: #ffffff;
  border-top: 1px solid #a8a8a8;
}
footer.semantic-footer ul li a
{
  font-weight: 400;
  color: #414141;
  border-right:1px solid #cbcbcb;
}


/*end of footer*/


/*--------------------------------
    14. Semantic ui
    --------------------------------*/

.header nav .navbar-collapse.semantic-col-min > ul > li > a
{
  color: #fff;
}

.header .semantic-col-min > ul > li.nav-item.active > a
{
  color: #898989;
}

.header .semantic-col-min ul li.nav-item .dropdown-menu .dropdown-item.active
{
  background: #c3c3c3 !important;
}

.org-theme.bootstrap-color .doc-head-section.semantic-doc-head {
  background: #1b1c1d;
}

.org-theme.bootstrap-color .doc-head-section.semantic-doc-head .upper-head {
  text-align: center;
  padding: 30px 55px 75px;
}

.org-theme.bootstrap-color .doc-head-section.semantic-doc-head .upper-head h2 {
  color: #e4e4e4;
}

.org-theme.bootstrap-color .doc-head-section.semantic-doc-head .upper-head h3 {
  color: #e4e4e4;
  font-size: 1.5em;
  font-weight: 300;
}

.org-theme.bootstrap-color .semantic-doc-head .start-btn {
  background: #e4e4e4;
  font-size: 0.85em;
  color: #1b1c1d;
}

.org-theme.bootstrap-color .semantic-doc-head .download-btn {
  border: 1px solid #e4e4e4;
  font-size: 0.85em;
  color: #e4e4e4;
  margin-left: 10px;
}

.semantic-doc-head .brand-name {
  color: #fff;
}

.content-section.semantic-content {
  width: 80%;
  padding-left: 50px
}

.semantic-min-nav {
  margin-left: 0px;
  margin-top: 53px;
  width: 20%;
  border-left: unset;
  border-right: 1px solid #ccc;
}

.semantic-min-nav nav a {
  color: #434343;
}

.semantic-note {
  border-left: 5px solid #1b1c1d;
}


/*end of semantic ui*/


/*--------------------------------
    15. Badge
    --------------------------------*/

.badge-secondary {
  color: #fff;
  border-radius: 2px;
}

.badge {
  display: inline-block;
  padding: 5px 12px;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
}


/*end of badge*/


/*--------------------------------
    16. Article
    --------------------------------*/

.content-section.article-content-sec {
  padding-left: 0px;
  width: 100%;
}

.article-inner-wrapper i {
  font-size: 4.35em;
}

.article-inner-wrapper .col-md-2 {
  text-align: center;
}

.article-content-list {
  margin-top: 30px;
}

.article-content-list h5 i {
  font-size: 1.57em;
  float: left;
  color: #f8d5c0;
}

.article-content-list h5 span {
  float: left;
  margin-left: 12px;
}

.article-content-list p {
  font-weight: 300;
  color: #222222;
}

.new-article-wrapper i {
  font-size: 1.75em;
  float: left;
  color: #f8d5c0;
}


/*end of article*/


/*--------------------------------
    17. Article 2
    --------------------------------*/

.article-helpful {
  text-align: center;
  padding: 30px 0;
  border-top: 1px solid #e5e5e5;
}

.article-helpful .box-btns {
  padding: 15px 0;
}

.article-helpful .box-btns a {
  width: 80px;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
  padding: 2px 12px;
  margin: 2px;
  border-radius: 50px;
  transition: 0.2s ease-in-out;
}

.article-helpful .box-btns a span {
  float: left;
  padding: 1px;
}

.article-helpful .box-btns a i {
  float: left;
  padding: 5px;
  font-size: 0.85em;
}

.article-helpful p {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.article-helpful p a {
  color: #0f1b31;
}

.comment_atr .img-atr {
  width: 60px;
  float: left;
}

.comment_atr .img-atr img {
  border-radius: 50px;
}

.comment_atr .detail-atr {
  padding-left: 15px;
  padding-top: 11px;
  float: left;
}

.comment_atr .detail-atr h6 {
  margin: 0px;
  font-size: 1em;
}

.comment_atr .detail-atr p {
  margin: 0px;
  padding: 0px;
  font-size: 0.85em;
}

.article-list {
  padding: 0 0 30px;
}

.article-list .inner-list {
  padding: 20px 20px 0;
  border: 1px solid #e5e5e5;
}

.article-list .inner-list h5 {
  padding-bottom: 20px;
}

.article-list .inner-list li {
  padding-bottom: 10px;
}

.article-list .inner-list li a {
  color: #444;
  font-weight: 300;
}

.comments .atr-comment {
  padding-top: 20px;
}

.comments .atr-comment .inner-comment {
  position: relative;
  padding-bottom: 10px;
}

.comments .atr-comment .inner-comment a {
  position: absolute;
  right: 0;
  bottom: 10px;
}

.comments .atr-comment .rply-atr {
  width: 80%;
  float: right;
  border-top: 1px solid #e5e5e5;
  padding-top: 20px;
}

.created-atr .comment_atr {
  margin-bottom: 20px;
}

.created-atr .comment_atr .detail-atr p {
  color: #aaa;
}

.leave_cmt {
  margin-top: 10px;
  border-top: 1px solid #e5e5e5;
  padding: 30px 0;
}

.leave_cmt form {
  padding-top: 20px;
}

.leave_cmt form .input-wrapper {
  width: 100%;
}

.leave_cmt form .input-wrapper input {
  width: 100%;
  height: 40px;
  border: 1px solid #e5e5e5;
  padding: 0px 20px;
  margin: 15px 0;
  border-radius: 2px;
}

.leave_cmt form .input-wrapper input.submit-btn {
  width: 150px;
  text-transform: uppercase;
  font-size: 0.92em;
  letter-spacing: 1px;
  color: #fff;
}

.leave_cmt form textarea {
  border: 1px solid #e5e5e5;
  padding: 10px 20px;
  width: 100%;
  resize: none;
  border-radius: 2px;
}

.doc-ui-table tr th {
  background: #f8f8f8;
  border-bottom-width: 1px !important;
}

.main-content-inner {
  padding: 30px 0;
}

.article-right-nav {
  width: 100%;
  margin-top: 40px;
}


/*--------------------------------
  18. 404
  --------------------------------*/

.error-sec {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.error-body,
.error-html {
  height: 100%;
}

.error-sec h2 {
  font-size: 5em;
  font-weight: 300;
  color: #949494;
}

.error-sec p {
  font-size: 1.42em;
  color: #969191;
  letter-spacing: 2px;
}

.error-sec a {
  color: #fff;
  border: 0px;
  padding: 7px 10px;
  width: 125px;
  margin: 5px;
  display: inline-block;
  border-radius: 3px;
}
.error-body header
{
  position: fixed;
  width: 100%;
  top: 0;
}
.error-body footer
{
  position: fixed;
  width: 100%;
  bottom: 0;
}


.contact-form {
  max-width: 900px;
  margin: 0 auto;
  padding: 50px 0 0;
}

.contact-form input {
  width: 100%;
  height: 45px;
  border: 1px solid #d8d8d8;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 3px;
}

.contact-form textarea {
  width: 100%;
  border: 1px solid #d8d8d8;
  padding: 20px;
  margin-bottom: 30px;
  resize: none;
  border-radius: 3px;
}

.contact-form input[type=submit] {
  width: 200px;
  padding: 0;
  background: #ffb662;
  border: 1px solid #ffb662;
  font-size: 0.92em;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 3px;
}


/*end of contact*/


/*--------------------------------
    20. FAQ
    --------------------------------*/

.faq-div {
  padding: 30px 0;
  border-bottom: 1px solid #f2f2f2;
}

.faq-div h4 span {
  color: #444;
  margin-left: 10px;
  font-size: 1em;
}

.faq-form .faq-div:last-child {
  border-bottom: 0px;
}
/*end of FAQ*/

/*--------------------------------
  21. chat box
  --------------------------------*/

  #chat-circle {
  position: fixed;
  bottom: 50px;
  right: 50px;
  background: #1740c5;
  width: 45px;
  height: 45px; 
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  cursor: pointer;
}

.btn#my-btn {
     background: white;
    padding-top: 13px;
    padding-bottom: 12px;
    border-radius: 45px;
    padding-right: 40px;
    padding-left: 40px;
    color: #5865C3;
}
#chat-overlay {
    background: rgba(255,255,255,0.1);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: none;
}


.chat-box {
  display:none;
  background: #efefef;
  position:fixed;
  right:30px;
  bottom:50px;  
  width:350px;
  max-width: 85vw;
  max-height:100vh;
  border-radius:5px;
  z-index: 999;
}
.chat-box-toggle {
  float:right;
  margin-right:15px;
  cursor:pointer;
}
.chat-box-header {
  height:50px;
  border-top-left-radius:5px;
  border-top-right-radius:5px; 
  color:white;
  text-align:center;
  font-size:16px;
  padding-top:10px;
}
.chat-box-body {
  position: relative;  
  height:370px;  
  height:auto;
  border:1px solid #ccc;  
  overflow: hidden;
}
.chat-box-body:after {
  content: "";
  background-image:;
  opacity: 0.1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height:100%;
  position: absolute;
  z-index: -1;   
}
#chat-input {
  background: #f4f7f9;
  width:100%; 
  position:relative;
  height:47px;  
  padding-top:10px;
  padding-right:50px;
  padding-bottom:10px;
  padding-left:15px;
  border:none;
  resize:none;
  outline:none;
  border:1px solid #ccc;
  color:#888;
  border-top:none;
  border-bottom-right-radius:5px;
  border-bottom-left-radius:5px;
  overflow:hidden;  
}
.chat-input > form {
    margin-bottom: 0;
}
#chat-input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #ccc;
}
#chat-input::-moz-placeholder { /* Firefox 19+ */
  color: #ccc;
}
#chat-input:-ms-input-placeholder { /* IE 10+ */
  color: #ccc;
}
#chat-input:-moz-placeholder { /* Firefox 18- */
  color: #ccc;
}
.chat-submit {  
  position:absolute;
  bottom:3px;
  right:10px;
  background: transparent;
  box-shadow:none;
  border:none;
  border-radius:50%;
  color:#5A5EB9;
  width:35px;
  height:35px;  
}
.chat-logs {
  padding:15px; 
  height:370px;
  overflow-y:scroll;
}

.chat-logs::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

.chat-logs::-webkit-scrollbar
{
  width: 5px;  
  background-color: #F5F5F5;
}

.chat-logs::-webkit-scrollbar-thumb
{
  background-color: #5A5EB9;
}

.chat-msg.user > .msg-avatar img {
  width:45px;
  height:45px;
  border-radius:50%;
  float:left;
  width:15%;
}
.chat-msg.self > .msg-avatar img {
  width:45px;
  height:45px;
  border-radius:50%;
  float:right;
  width:15%;
}
.cm-msg-text {
  background:white;
  padding:10px 15px 10px 15px;  
  color:#666;
  max-width:75%;
  float:left;
  margin-left:10px; 
  position:relative;
  margin-bottom:20px;
  border-radius:30px;
}
.chat-msg {
  clear:both;    
}
.chat-msg.self > .cm-msg-text {  
  float:right;
  margin-right:10px;
  background: #5A5EB9;
  color:white;
}
.cm-msg-button>ul>li {
  list-style:none;
  float:left;
  width:50%;
}
.cm-msg-button {
    clear: both;
    margin-bottom: 70px;
}

/*--------------------------------
  end of chat box
  --------------------------------*/

/*--------------------------------
  22. color palette
  --------------------------------*/
.color-palette
{
  width: 100px;
  background: #fff;
  position: fixed;
  right: -100px;
  top: 50%;
  margin-top: -50px;
  padding: 7px 0;
  z-index: 9;
  text-align: center;
  border: 1px solid #ccc;
  box-shadow: -3px 3px 8px -2px #ccc;
  transition: .3s ease-in-out;
}
.open-plt
{
  right: 0;
  transition: .3s ease-in-out;
}
.color-palette a#color-bar
{
  position: absolute;
  left: -35px;
  font-size: 25px;
  background: #fff;
  width: 35px;
  height: 35px;
  top: 50%;
  margin-top: -16px;
  display: block;
  padding: 1px 0;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: -3px 3px 8px -2px #ccc;
}
.color-palette h4
{
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.color-palette ul
{
  padding: 0px;
  margin: 0;
}
.color-palette li
{
  width: 50%;
  display: inline-block;
  float: left;
  text-align: center;
}
.color-palette li a
{
  width:30px;
  height: 30px;
  display: block;
  margin: 4px auto;
}
.color-palette li a#blue-wlt
{
  background: #0a2c9c;
}
.color-palette li a#gradient-wlt
{
  background: linear-gradient(-20deg, #2b5876 0%, #4e4376 100%);
}
.color-palette li a#green-wlt
{
  background: #2e9e3c;
}
.color-palette li a#red-wlt
{
  background: #960d0d;
}
.color-palette li a#img-wlt
{
  background: #000;
}
/*end of color palette*/

/*--------------------------------
  23. Login
  --------------------------------*/
  .login-wrapper
  {
    width:100%;
    height: 100%;
  }
  .login-left-wrapper
  {
    width: 35%;
    float: left;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .form-login
  {
    padding: 80px 0;
  }
  .form-login .heading-login label
  {
    font-weight: 800;
    font-size: 20px;
    margin-bottom: 30px;
  }
  .form-login .heading-login a
  {
    color: #a5a5a5;
    font-weight: 600;
    margin-left: 20px;
  }
  a.forget-password
  {
    color: #a5a5a5;
    font-weight: 400;
  }
  .form-login input
  {
    width: 100%;
    height: 48px;
    border: 1px solid #d8d8d8;
    padding: 10px;
    border-radius: 4px;
  }
  button.login-btn
  {
    display: block;
    width: 100px;
    height: 40px;
    margin-top: 30px;
    background:#22d187;
    border:0px;
    color: #fff;
    border-radius: 3px;
  }
  .login-right-wrapper
  {
    width: 65%;
    float: left;
    background: #103669;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
  }
  .login-right-inner
  {
    width: 50%;
    position: relative; 
    z-index: 9;
  }
  .login-right-wrapper:after
  {
    content: "";
    width: 300px;
    height: 300px;
    background: #0a3c80;
    border-radius: 50%;
    display: block;
    position: absolute;
    left: -40px;
    bottom: -40px;
  }
  .login-right-inner h1
  {
    color: #fff;
    font-weight: 800;
    text-transform: capitalize;
  }
  .login-right-inner p
  {
    color: #ccc;
  }
  .login-right-inner h1 span
  {
    display: block;
  }
  .login-content-inner-wrapper
  {
    width: 100%;
    padding: 0 100px;
  }
  .login-content-inner-wrapper h3
  {
    font-weight: 800;
    color:#222;
  }
  .login-with ul
  {
    padding: 0;
    margin-top: 30px;
  }
  .login-with ul li
  {
    display: inline-block;
    float: left;
    text-align: center;
  }
  .login-with ul li a
  {
    width: 40px;
    height: 40px;
    background: #000;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin-right: 10px;
  }
  .login-with ul li a.facebook-rd
  {
    background: #3b5998;
  }
    .login-with ul li a.twitter-rd
  {
    background: #00acee;
  }
    .login-with ul li a.linkedin-rd
  {
    background: #0e76a8;
  }
  ul.app-screens
  {
    padding: 0;
    text-align: center;
  }
  ul.app-screens li
  {
    width: calc(100% / 3);
    float: left;
    list-style: none;
    padding: 15px;
  }
  ul.app-screens .image-wrapper
  {
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 2px 2px 8px 0px #d3d3d3;
    display: block;
  }
  ul.app-screens li h5
  {
    margin-top: 20px;
  }



/*

Template: VedhaDocs - The complete documentation template
Version: 1.0
Author: vedhathemes

NOTE: This file contain responsive style of template

*/
/*================================================
[  Table of contents  ]
================================================

:: Large devices (desktops, 992px and less)
:: Medium devices (tablets, 768px and less)
:: Medium devices (tablets, 768px and up)
:: Small device (Mobile, 500px and less)

======================================
[ End table content ]
======================================*/

/*----------------
01. Large devices (desktops, 992px and less)
----------------*/
@media screen and (max-width:992px)
{
.with-sidebar .brand-name
{
  display: block;
}
  .page-wrapper.toggled .sidebar-wrapper
{
  left: -300px !important;
}
.page-wrapper.toggled #show-sidebar
{
  left: -3px !important;
}
.page-wrapper .sidebar-wrapper
{
  left:0px !important;
}
.page-wrapper #show-sidebar
{
  left: -40px !important;
}
.page-wrapper.toggled .page-content
{
  padding-left:0px; 
}
 /*--------------------------------
   01.1 header
   --------------------------------*/
.header nav
{
  padding: 5px 25px;
  display: block;
  text-align: right;
}
.navbar .navbar-toggler
{
  padding: 0px 4px 4px 4px;
}
.header nav .navbar-collapse li.nav-item > a
{
  padding: 7px 0px 7px;
  border-bottom:1px solid #f4f4f4;
}
.navbar-light .navbar-toggler-icon
{
  width: 18px;
  height: 18px;
}
#show-sidebar
{
  width: 43px;
  padding: 5px;
  top: 37px;
}
#show-sidebar i
{
  font-size: 10px;
}
.search-wrapper
{
  display: none;
}
.new-index-header button
{
  display: none;
}
/*--------------------------------
  01.2 bootstrap head
  --------------------------------*/

.bootstrap-head
{
  border-bottom: 1px solid #7e5ab5;
}
.bootstrap-head-min
{
  border-bottom: 1px solid #e9dbff;
}
.bootstrap-head .navbar .navbar-toggler
{
  background: #916fc5;
  height: 22px;
  width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  float: right;
}
.bootstrap-head .navbar .navbar-toggler span
{
  font-size: 13px;
  padding: 0;
}
.bootstrap-nav
{
  width: 100%;
}
.bootstrap-head .navbar ul
{
  width:100%;
}
.header.bootstrap-head nav .navbar-collapse li.nav-item > a
{
  padding: 7px 0px 7px;
  border-bottom:1px solid #7e5ab5;
}
.header.bootstrap-head nav .navbar-collapse li.nav-item > a
{
  border-bottom: 1px solid #e9dbff;
}
.semantic-doc-head .header
{
  border-bottom:1px solid #777;
}
.semantic-doc-head .header .navbar .navbar-toggler
{
  width: 22px;
  height: 22px;
  background: #e4e4e4;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  padding: 0;
  padding: 0;
  border-radius: 2px;
}
.semantic-col-min
{
  width: 100%;
}
.semantic-col-min ul
{
  width:100%;
}
.page-wrapper.toggled .sidebar-wrapper.relative-sidenav
{
  left: 0 !important;
}
/*end of bootstrap head*/
/*end of header*/

/*--------------------------------
  01.3 pages
  --------------------------------*/
.org-theme.bootstrap-color .upper-head
{
  padding: 10px 25px 45px !important;
}
/*end of pages*/

/*-------------------------
  home page
  ----------------------------*/
  .banner-input
  {
    padding: 100px 0;
  }
  .box-wrapper
  {
    margin: 20px 0 0;
  }
/*end of home page*/
/*-------------------------
  Login page
  ----------------------------*/
  .login-right-inner h1
  {
    font-size: 30px;
  }
  .login-content-inner-wrapper
  {
    padding: 0 50px;
  }
  /*end of login page*/
}

/*----------------
02. Medium devices (tablets, 768px and less)
----------------*/
@media screen and (max-width:768px)
{
body
{
  font-size: 12px;
}

/*--------------------------------
  02.1 right sidebar
  --------------------------------*/

.right-nav
{
  display: none;
}
.page-wrapper.toggled .sidebar-wrapper.relative-sidenav
{
  display: none;
}
/*end of right sidebar*/

/*--------------------------------
  02.2 pages
  --------------------------------*/
.banner-input
{
  padding: 50px 15px;
}
.page-wrapper .page-content div.main-content2
{
  padding: 20px 19px;
}
.content-section
{
  padding: 10px 10px;
  width:100%;
}
pre
{
  width:100%;
}
/*end of pages*/

/*--------------------------------
  02.3 index page
  --------------------------------*/
.main-articles ul li
{
  width: calc(100% / 2);
}
/*end of index page*/

/*--------------------------------
  02.4 doc pages
  --------------------------------*/
.upper-head h2
{
  margin: 0px 0 15px;
}
.upper-head
{
  padding: 20px 20px 20px;
}
.upper-head p
{
  width: 100%;
}

/*--------------------------------
  02.5 article
  --------------------------------*/
.article-inner-wrapper .col-md-2
{
  text-align: left;
}
.article-inner-wrapper i
{
  margin-bottom: 23px;
  display: block;
}
.article-content-sec .section
{
  padding: 30px 0;
}
.introduction
{
  padding: 50px 0;
}

/*end of article*/

/*end of doc pages*/

/*--------------------------------
  02.6 footer
  --------------------------------*/
footer
{
  padding: 12px 0;
}
footer ul li a
{
  font-size: .9em;
}
/*end of footer*/

/*--------------------------------
  login
  --------------------------------*/
  .login-left-wrapper
  {
    width: 100%
  }
  .login-content-inner-wrapper
  {
    text-align: center;
  }
  button.login-btn
  {
    margin: 20px auto 0;
  }
  .login-with ul
  {
    text-align: center;
  }
  .login-with ul li
  {
    float: none;
  }
  /*end of login*/
  ul.app-screens li
{
  width: calc(100% / 2);
}
}

/*----------------
03. Medium devices (tablets, 768px and up)
----------------*/
@media screen and (min-width: 768px) {
.page-wrapper.toggled .page-content {
  padding-left: 300px;
}
}

/*----------------
04. Small device (Mobile, 500px and less)
----------------*/
@media only screen and (max-width: 500px) {
 .chat-logs {
      height:40vh;
  }
   ul.app-screens li
{
  width: calc(100%);
}
}


/*

Template: VedhaDocs - The complete documentation template
Version: 1.0
Author: vedhathemes

*/
/*================================================
[  Table of contents  ]
================================================

:: Sidemenu
:: Header
:: Layout
:: Article
:: Article-2
:: Footer
:: 404
:: Pagination
:: Badge
:: Contact
:: Faq
:: Chat-Box-Header

======================================
[ End table content ]
======================================*/

/*============================
sidemenu
============================*/
.org-theme .sidebar-wrapper,
#show-sidebar {
  background: linear-gradient(-20deg, rgba(9,46,168,1) 0%, rgba(15,37,110,1) 100%);
}

.sidebar-wrapper .sidebar-brand{
    background: #071a58;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul li.active a
{
  color: #6b83d3;
}
.sidebar-wrapper .sidebar-menu ul li.side-list.active > a
{
  color: #6b83d3;
}
/*end of sidemenu*/

/*============================
  Header
  ============================*/
.doc-head-section {
    background: #dde5ff;
}
.search-wrapper {
  border-bottom: 1px solid #afb6cd;
}

.header-full {
  border-bottom: 1px solid #cad3f0;
}
.header-full .search-wrapper{
  background: #ccd8ff;
}

/*end of header*/

/*============================
  layout
  ============================*/
.imp-note{
  border-left: 5px solid #9fb5ff;
}

#features h4 i {
  color: #8da7ff;
}
/*end of layout*/

/*============================
  article
  ============================*/
.article-inner-wrapper i
{
  color: #698af6;
}
.article-content-list h5 i
{
  color: #b8c8ff;
}
/*end of article*/

/*============================
  article-2
  ============================*/
.article-helpful .box-btns a
{
  border: 1px solid #7394ff;
  color: #7394ff;
}
.article-helpful .box-btns a:hover {
  background: #7394ff;
  color: #fff;
}
.comments .atr-comment .inner-comment a
{
  color:#7394ff;
}
.leave_cmt form .input-wrapper input.submit-btn
{
  background: #0a2d9e;
}
ul.pro-ul li:before
{
  border: 1px solid #0a2d9e;
}
/*end of article-2*/

/*============================
  footer
  ============================*/
footer
{
  background: #dde5ff;
}
footer ul li a
{
  color: #173164;
  border-right: 1px solid #6f7fab;
}
/*end of footer*/

/*============================
  404
  ============================*/
.error-sec a
{background: rgba(9,46,168,1);
  background: -moz-linear-gradient(top, rgba(9,46,168,1) 0%, rgba(15,37,110,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(9,46,168,1)), color-stop(100%, rgba(15,37,110,1)));
  background: -webkit-linear-gradient(top, rgba(9,46,168,1) 0%, rgba(15,37,110,1) 100%);
  background: -o-linear-gradient(top, rgba(9,46,168,1) 0%, rgba(15,37,110,1) 100%);
  background: -ms-linear-gradient(top, rgba(9,46,168,1) 0%, rgba(15,37,110,1) 100%);
  background: linear-gradient(to bottom, rgba(9,46,168,1) 0%, rgba(15,37,110,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#092ea8', endColorstr='#0f256e', GradientType=0 );
}
/*end of 404*/

/*============================
  pagination
  ============================*/
.page-link {
    color: #0c2a8f;
}
.page-item.active .page-link {
  background-color: #0d277d;
  border-color: #0d277d;
}
/*end of pagination*/

/*============================
  badge
  ============================*/
.badge-secondary
{
    background-color: #728ff2;
}
/*end of badge*/

/*============================
  contact
  ============================*/
.contact-form input[type=submit] {
  background: #0a2d9e;
  border: 1px solid #0a2d9e;
  color: #fff;
}
/*end of contact*/

/*============================
  faq
  ============================*/
.faq-div h4 {
  color: #0a2d9e;
}
/*end of faq*/

/*============================
  chat-box-header
  ============================*/
.chat-box-header
{
  background: #113dc9;
}

/*end of chat-box-header*/
@media screen and (max-width: 992px) {
  .page-wrapper.toggled #show-sidebar {
    left: -3px !important;
  }
}

@media screen and (max-width: 768px) {
  .page-wrapper .page-content div.main-content2 {
    padding: 20px 19px;
  }
}
.ti-angle-double-right::before {
  content: "=";

}
@media screen and (max-width: 992px) {
  #show-sidebar i {
    font-size: 10px; 
  }
}

.ti-close::before {
  content: "X";

}