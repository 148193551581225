
*, ::after, ::before {
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

#bkp-main{
  background-color: #FFFFFF;
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
}

.App {
 
}
  



 
.App .header
{   
   color: #fff;
   font-size: 16px;
   text-align: right;
}

.App .header .bar
{
  background-color: #21337a;



  -webkit-box-shadow: -1px 3px 19px -11px rgba(66, 68, 90, 1);
  -moz-box-shadow: -1px 3px 19px -11px rgba(66, 68, 90, 1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.06);

  padding: 10px;

  position: relative; /*fixed*/
  top:0px;
  width: 99%;

  z-index: 10;

  display:flex;
  align-items: center; 
  flex-wrap: wrap;
  justify-content: flex-start;
  border-bottom: 3px solid rgba(255, 255, 255, 0.15);
  border-color: #e30d1a;
}


.App .header .bar a
{
  color: #fff;
  text-decoration: none;
}

.App .header .bar .phone
{
   display: inline-flex;
   width: auto;
   text-align: left;
   margin-right: 1rem;
}

.App .header .bar .mail
{
   display: inline-flex;
   width: auto;
   text-align: center;
   margin-right: 1rem;
}

.App .header .bar .user
{
   display: inline-flex;
   width: auto;
   text-align: right;
   flex-basis: auto;
   justify-content: flex-end;
   flex-direction: row;
   margin-left: 40px;
}

.App .header .bar .user img
{
   width: 18px;   
   position: relative;
   top: 2px;
}

.App .header .bar .font-size-buttons
{
  display: inline-flex;
  width: auto;
  text-align: right;
  flex-basis: auto;
  justify-content: flex-end;
  flex-direction: row;
  margin-left: 20px;
}

.App .header .bar .font-size-plus
{
  font-size: 22px;
  cursor: pointer;
  margin-right: 15px;
}

.App .header .bar .font-size-minus
{
  font-size: 22px;
  cursor: pointer;
}
.nav-item.nav-link .font-size-minus
{
  font-size: 22px;
  cursor: pointer;
}
.nav-item.nav-link .font-size-plus
{
  font-size: 22px;
  cursor: pointer;
}

.App .header .bar .brightness-button
{
  cursor: pointer;
  display: inline-flex;
  width: auto;
  text-align: right;
  justify-content: flex-end;
  flex-direction: row;
  margin-left: auto; 
}

.App .header .bar .contrast-button
{
  cursor: pointer;
  display: inline-flex;
  width: auto;
  text-align: right;
  justify-content: flex-end;
  flex-direction: row;
  margin-left: 15px; 
}

.App .header .bar .contrast-button .conB
{

  background-image: url('../src/image/contrast_icon_w.png');
  background-size: cover; 
  width: 30px;
  height: 25px;
}

.App .footer
{
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #2f2d66;
   color: #fff; 
   text-align: center;
   position: fixed;
   width: 100%;
   bottom: 0px;
}

.App .header .menu
{  
  text-align: justify;    
  color: #000;
  /*padding-left: 10%;
  padding-right: 10%;*/

  margin-top: 0px; /*50px*/
}


.App .header .menu .item
{
  display: inline-block;
  text-align: center;
  padding-left: 1%;
  padding-right: 1%;
  font-weight: bold;
  width: 31%; /*14%*/   
}


.App .header .menu .item a
{
  text-decoration: none;
  color: #000;
}

.App .header .menu .item_padding{
   padding-top: 30px;
   padding-bottom: 30px;
}

.App .header .menu .item_padding:hover
{
  background-color: #e1e1e1;
}

.App .container .training .items .item .info .title a:hover{
  color: #2C4894;
}

.App .header .menu .item img
{
    height: 80px;
    vertical-align: middle;
}

.App .header .imageSplit
{
  height: 200px;
  background-image: url('image/slider-bg.jpg');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position-y: center;
  

  -moz-box-shadow: inset 10px 10px 50px #fff;
  -webkit-box-shadow: inset 10px 10px 50px #fff;
  box-shadow: inset 10px 10px 50px #fff;
 
}

.App .header .imageSplit .pageTitle
{
   text-align: left;    
   color: #fff;
   font-size: 50px;
   padding-top: 50px;
   padding-left: 10%;
   padding-right: 10%;
}

.App .header .imageSplit .pageTitle .small
{
  font-size: 20px;
}


/*.App .container 
   {
       max-width: 100%; 
       margin: 0 auto;
   }*/

.App .container .training
{
  padding-left: 10%;
  padding-right: 10%;  
  padding-bottom: 150px;
}

.App .container .training .items
{
  
}

.App .container .training .items .item
{
     margin-top: 15px;
     margin-bottom: 15px;   
     width: 100%;
     border-top: 4px solid #D89B3E;     
     display: inline-flex;  
     justify-content: center;
     align-items: center;
}




       


.App .border-left-right
{
  border-left: 1px solid #bbb;
  border-right: 1px solid #bbb;
}


.App .container .training .items .item .info
{   
  line-height: 1.5;
}

.App .container .training .items .item .info .title a
{
   font-size: 20px;
   color: #091C50;
   cursor: pointer;
   text-decoration: none;
  /* padding-top: 4px; */
   font-weight: 600;
}


.App .container .training .items .item:hover
{
  background-color: #E0BA5A;
}

.App .container .training .items .item .info .firm
{
   font-size: 16px;
   color: #444;
   cursor: pointer;
}

.App .container .training .items .item .info .dates
{
   font-size: 12px;  
   color: #444; 
   cursor: pointer;
}

.App .container .training .items .item .info_center
{
  text-align: center;
  width: 100%;
}

.App .container .training .items .item .info .price
{
    padding: 20px;
    font-size: 16px;
    font-weight: bold;
    color: #221D1D;
}


.App .container .training .searchEngine
{
     text-align: center;
     padding: 50px;
}

.App .container .training .searchEngine input
{
   padding: 10px;
   width: 60%;
}


.App .container .training .details
{
    
}


.App .container .training .details .location
{
    border-bottom: 4px solid #D89B3E;;
    padding: 14px;
}

.App .container .training .details .location a
{
  text-decoration: none;
  color: #000;
}


.App .container .training .details .location img
{
  width: 14px;
}

.App .container .training .details .detailsButton
{
    text-align: right;
    padding-top: 5px;
    padding-bottom: 5px;
    float: right;
}

.App .container .training .details h1
{
    color: #172C66;
    font-size: 30px;
    font-weight: 400;
}

.App .container .training .details .slitInfo
{
   margin-bottom: 3px; 
    
}

.App .container .training .details .slitInfo .subTitle
{
  color: #25377E;
  font-size: 12px;    
  font-weight: 400;
  padding:7px; 
  background-color:#e1e1e1;
  border-radius: 3px;
  font-size: 18px;
}

.App .container .training .details .slitInfo ul
{    
  margin: 0px;
  padding: 0px;
  list-style:none;
  margin-bottom: 20px;

 
}
 
.App .container .training .details .slitInfo ul li
{
  padding-top: 15px;
}

.App .container .training .details .slitInfo ul li img
{
  width: 16px;
  position: relative;
  top: 2px;
}

.App .container .training .details .slitInfo  p
{
  
}


.App .container .training .details .slitInfo .price
{
   font-size: 18px;
   font-weight: bold;
   color: #192E68;
   border-top: 1px solid #ccc;
   padding: 5px;
}


.AppLogin .imageSplit
{
  display: none;
}

.AppDashboard .imageSplit
{
  display: none;
}

.AppDashboard .menu
{
  display: none;
}


/*----REGISTER----------LOGIN-----------------------*/





.App .loginWrapper
{

   display: flex;
   justify-content: center;
   flex-wrap: wrap;
   margin-left: 10%; 
   margin-right: 10%;
   margin-top: 30px;
}

.App .loginWrapper .register
{
  display: inline-block;
  width:48%;
  vertical-align: top;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;


}
.App .loginWrapper .login
{
  display: inline-block; 
  width: 48%;
  vertical-align: top;
 /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;*/
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
 
}



.App .loginWrapper .register .simplyForm .formField input
{
  border-radius: 7px;
}

.App .loginWrapper .login .simplyForm .formField input
{
  border-radius: 7px;
}

.App .loginWrapper .register .simplyForm .formField input[type="submit"]
{
  
}

.App .loginWrapper .login .simplyForm .formField input[type="submit"]
{
  
}


.App .formTitle
{
  
   text-align: center;
   color: #05184B;
   margin-top: 10px;
   margin-bottom: 10px;
   font-weight: 700;
}

.App .simplyForm
{ 
  background-color: #f2f3f9;
  border: 1px solid #ddd;
  width: 100%;
  padding: 10px;  
}

/*------------------------------------*/

.App .correct
{
   background-color: #daffd2!important;
   border: 1px solid #2e6b21!important;
}

.App .incorrect
{
   background-color: #ffa0a0!important;
   border: 1px solid #b40606!important;
}

.App .loginWrapper .register.d-block-register
{
   display: block!important;
   margin: auto!important;
}

.App .text-red{
  color: #b40606;
  font-size: 18px;
  font-weight: bold;
}

.App .alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.App .alert {
  /*position: relative;*/
  display: flex;
  padding: .25rem .25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  border-radius: .25rem;
}

.App  .errorForm
{
  color: #d00;
  font-size: 11px;
}

.App  .okForm
{
  color: #0d0;
  font-size: 11px;
}

.App .text-center{text-align: center;}


.App .simplyForm .formField
{
   display:  inline-block; 
   width: 100%;
   margin-bottom: 10px;
}

.App .simplyForm .formField50
{
   display:  inline-block; 
   width: 45%;
   margin: 2%;    
}

.App .simplyForm label
{
   display: block; 
   font-size: 12px;
}

.App .required
{
  color: #f00;
  font-size: 18px;
}



.App .simplyForm input, .App .simplyForm select, .App .simplyForm textarea
{ 
     display: block;
     padding: 1%;
     padding-top: 10px;
     padding-bottom: 10px;  
     width: 94%;   
}


.App .formCheckbox
{
    text-align: left;    
    font-size: 12px;
}

.App .formCheckbox input[type=checkbox]
{
    display: inline;
    width: auto;
}
 

.App .dashboard
{
    margin: 1%;
    margin-top: 20px;          
}



/*--------MENU-----------*/


.App .dashboard .d_menu
{
    width: 15%; 
    margin: 0%; /*1%*/
    float: left;  
}

.App .dashboard .d_menu a 
{
  padding: 0px;
  margin: 0px;
  list-style: none;
  background-color: #555;

  -webkit-box-shadow: 8px 8px 24px -12px rgba(66, 68, 90, 1);
  -moz-box-shadow: 8px 8px 24px -12px rgba(66, 68, 90, 1);
  box-shadow: 8px 8px 24px -12px rgba(66, 68, 90, 1);
}

.App .dashboard .d_menu a li
{
  padding: 10px;
  background-color: #414956;
  color: #fff;
  margin-top: 1px;
  margin-bottom: 1px; 
  font-size:16px; 
  border-left: 3px solid transparent;
  background-color: #21337a;
  border-radius: 3px;
}



.App .dashboard .d_menu a li 
{
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}

.App .dashboard .d_menu a li:hover, .App .dashboard .d_menu a li.selected
{
  background-color: #4b84db;
  border-left-color: #1857b4;
}





.App .ui.vertical.menu{width:auto;font-family: 'Montserrat', sans-serif; font-size: 16px;}
.App .ui.vertical.menu .item{background-color: #21337a;border-left-color: #1857b4; color: #fff;padding: 15px;width:100%}
.App .ui.menu a.item:hover {background-color: #4b84db; border-left-color: #1857b4; }
.App .ui.vertical.menu a.active{background-color: #4b84db; border-left-color: #1857b4; }






.App .dashboard .d_menu
{
    width: 15%; 
    margin: 0%; /*1%*/
    float: left;  
}

.App .dashboard .d_menu ul 
{
  padding: 0px;
  margin: 0px;
  list-style: none;
  background-color: #555;

  -webkit-box-shadow: 8px 8px 24px -12px rgba(66, 68, 90, 1);
  -moz-box-shadow: 8px 8px 24px -12px rgba(66, 68, 90, 1);
  box-shadow: 8px 8px 24px -12px rgba(66, 68, 90, 1);
}

.App .dashboard .d_menu ul li
{
  padding: 10px;
  background-color: #414956;
  color: #fff;
  margin-top: 1px;
  margin-bottom: 1px; 
  font-size:16px; 
  border-left: 3px solid transparent;
  background-color: #21337a;
  border-radius: 3px;
}



.App .dashboard .d_menu ul li a
{
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}

.App .dashboard .d_menu ul li:hover, .App .dashboard .d_menu ul li.selected
{
  background-color: #4b84db;
  border-left-color: #1857b4;
}

/*----------------------------------*/


.App .dashboard .d_menu_tab
{
  width: 84%; 
  margin: 0%; 
  float: right;
  min-height: 900px;
  padding-bottom: 130px;
}

.App .dashboard .d_menu_tab .list
{
  
}

.App .loading
{
  text-align: center;
}

.App .loading img
{
     width: 200px;   
     margin-top: 50px;  
}

.App  img.img_mini
{
     width: 80px;       
}

.App .dashboard .d_menu_tab .list .search_bar
{
   background-color: #E1E6FF;
   border: 1px solid #ddd;
   padding: 5px;
}

.App .dashboard .d_menu_tab .list .pagger
{ 
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 12px;
}

.App .dashboard .d_menu_tab .list .pagger .pleft
{
  display: inline-block;
  width: 50%;
}

 

.App .dashboard .d_menu_tab .list .pagger .pright
{
  display: inline-block;
  width: 50%;
  text-align: right;
}

.App .dashboard .d_menu_tab .list .pagger  .page_info
{
     display: inline-block;
     padding: 3px 7px 3px 7px;
     background: #414956;
     color: #fff;
     margin-left: 2px;
     cursor: pointer;
}

.App .dashboard .d_menu_tab .list .pagger  .page_info:hover, .App .dashboard .d_menu_tab .list .pagger  .page_info_selected
{
  color: #f00;
}


.App .dashboard .d_menu_tab .list .table_content
{
    margin-top: 20px;
    box-sizing: content-box;
}



.App .dashboard .d_menu_tab .list .search_bar .filter
{
    display: inline-block; 
}

.App .dashboard .d_menu_tab .list .search_bar .filter .filter_label
{
    display: inline-block;
    margin: 2px;
    color: #444;
    font-size: 12px;
}

.App .dashboard .d_menu_tab .list .search_bar .filter .filter_content
{
    display: inline-block;
    margin: 2px;
}


.App .dashboard .d_menu_tab .list .search_bar .filter .filter_content input[type=text], .App .dashboard .d_menu_tab .list .search_bar .filter .filter_content input[type=password], .App .dashboard .d_menu_tab .list .search_bar .filter .filter_content select, .App .dashboard .d_menu_tab .list .search_bar .filter .filter_content textarea
{
    padding: 8px;
    border: 1px solid #ddd;
    display: inline-block;
    background-color: #fff;
}

.App .dashboard .d_menu_tab .list .search_bar .filterButton
{
  display: block;
  margin-top: 15px;
  margin-bottom: 5px;
  text-align: left;
}

.App .dashboard .d_menu_tab .list .search_bar .filter .buttonSearch
{
  
    background-color:#0C59DB;
    border-radius:6px;
    border:1px solid #0C59DB;
    display:inline-block;
    cursor:pointer;
    color:#ffffff;
    font-family:Arial;
    font-size:12px;
    font-weight:bold;
    padding:6px 50px;
    text-decoration:none;
    margin-left: 5px;
    margin-right: 5px;
    vertical-align: middle;
}



.App .dashboard .d_menu_tab .list .table_content
{   
   margin-top: 10px;
  
}

.App  .tableScroll250
{
  max-height: 250px;
  overflow-y: scroll;
}

.App .dashboard .d_menu_tab .list .table_content .table
{
    width: 100%;
    border-spacing: 0px;    
}


.App .dashboard .d_menu_tab .list .table_content .table100
{
  width: 100%;
}

.App .dashboard .d_menu_tab .list .table_content .table td
{
  padding: 5px;
  font-size: 13px;
  height: 45px;
}

.App .dashboard .d_menu_tab .list .table_content .table thead tr
{   
 
}

.App .dashboard .d_menu_tab .list .table_content .table thead tr td
{
   xposition: sticky;
   background-color: #21337a;  
   font-weight:400;
   color: #fff;
   z-index: 0;
   top: 48px;
   text-align: center;
   padding: 7px;
}


.App .dashboard .d_menu_tab .list .table_content .table tbody tr td
{
    border-bottom: 1px solid #eee;
    border-left: 1px solid #eee;
    text-align: left;
}

.App .dashboard .d_menu_tab .list .table_content .table tbody tr:hover
{
    background-color: #eee;
}

.App .dashboard .d_menu_tab .list .table_content .table tbody .rowLink
{
    cursor: pointer;
}

.App .dashboard .d_menu_tab .list .sortby
{
  text-align: right;
  padding-top: 25px;
  padding-bottom: 10px;
  font-size: 12px;
}

.App .dashboard .details
{
  margin: 10px;
}

.App .dashboard .details0
{
  margin: 0px;
}

.App .dashboard .details .location
{
  color: #d00;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}

.App .dashboard .details .location img
{
    width: 20px;
    cursor: pointer;
} 


.App .dashContent
{
    background-color: #fff;
    padding: 2px;
}

.App .dashContent .info_lab
{
   display: inline-block;
   margin: 5px;    
}

.App .dashContent .info_lab .label
{
  display: inline-block;
  margin: 5px; 
  font-weight: bold;
  font-size: 11px;
  color: #2D4585;
}

.App .dashContent .info_lab .cont
{    
  font-size: 14px;
  line-height: 2.0;
}


.App .dashForm
{
    background-color: #E1E6FF;
    padding: 10px;
}

.App .dashForm .FormField
{
  display: block; 
  margin: 6px;
  margin-bottom: 6px;
}


.App .dashForm .FormFieldButton
{
  display: block; 
  margin: 6px;
  margin-bottom: 6px;
  text-align: right;
  padding-top: 5px;
  padding-bottom: 5px;   
  background-color: #95a2b7;
}

.App .dashForm .inlineField
{
  display: inline-block;
}
 

.App .dashForm .FormField label
{
   display: block;
   vertical-align: middle;
   font-size: 12px; 
   padding-top: 4px;
   padding-bottom: 4px;
}




.App .simplyTipAlert
{
     color: #d00;   
     font-size: 12px;  
     margin: 10px;
}

.App .tipMessage
{
  background-color: #d1f9c8;
  border: 1px dotted #2b8018;
  color: #2b8018;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}


.App .tipAlert
{
  background-color: #feafaf;
  border: 1px dotted #ad0606;
  color: #ad0606;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.App a 
{
  text-decoration: none;
  color: #000;
}

.App .field50 { width: 50px; }
.App .field100 { width: 100px; }
.App .field100p { width: 98%; }
.App .field200 { width: 200px; }
.App .field300 { width: 300px; }
.App .field400 { width: 400px; }
.App .field500 { width: 500px; }
.App .field600 { width: 600px; }
.App .field700 { width: 700px; }
.App .field800 { width: 800px; }
.App .field900 { width: 900px; }
.App .field100h { height: 100px; }


.App .tdcenter { text-align: center!important; }



.App  .firm_selector
{
  background-color: #2A4180;
  color: #fff;
  display: inline-block;
  padding: 10px 20px 10px 20px;
}

.App  .firm_selector .link_firm
{
  cursor: pointer;
}

.App   .window2222 
{  
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  border-style:solid;
  border-width:calc(30vh - 90px) calc(50vw - 300px);
  border-color:rgba(180, 190, 200, .85);  
  z-index: 5;
}

 

.App   .window 
{  
  position:fixed;
  top:10%;
  left:30%;
  width: 40%;
  max-height: 650px;
  background-color: #fff;
  border: 40px solid #414956;
  
  z-index: 5;

  -webkit-box-shadow: 0px 8px 39px -11px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0px 8px 39px -11px rgba(66, 68, 90, 1);
  box-shadow: 0px 8px 39px -11px rgba(66, 68, 90, 1);
}

.App   .window_form
{
  border: 30px solid #eee;  
}

.App   .window_list
{
     border: 30px solid #B5BECD;
}


.App  .window .container
{
    background-color: #ffffff;     
    color: #000;   
    max-height: 550px; 
    overflow-y: scroll;
}

.App  .window_form .container
{
    /*verflow-y: auto;*/
}

.App  .window .container_form
{
  padding: 5px; 
}

.App  .window .container .options
{
  margin: 20px;
  margin-left: 5px;
}

.App  .window .container ul
{
  padding: 0px;
  margin: 10px;
  list-style-type: none;
}

.App  .window .container ul li
{
  padding: 10px;
  background-color: #eee;
  margin-top: 1px;
  margin-bottom: 1px;
  font-size: 14px;
  cursor: pointer;
}

.App  .window .container ul li:hover
{
  background-color: #ccc;
}

.App .row_green
{
  background-color: #e6f9de;
}

.App .row_red
{
  background-color: #f8c1c1;
}


.App .text_green
{
  color: #12730b;
  font-weight: bold;
}

.App .text_red
{
  color: #d51e1e;
  font-weight: bold;
}

.App .text_bold
{  
  font-weight: bold;
}



/*-------------------------------------------------------*/
/*-------------------------------------------------------*/
/*-------------------------------------------------------*/
/*-------------------------------------------------------*/ 


.App input[type=file] { display: none; }

.App .listInnerTab
{
  padding: 0px;
  margin: 0px;
  list-style-type: none;
}

.App .listInnerTab li
{
  padding: 2px;
  margin: 0px;
  list-style-type: none;
}

.App .row_green
{
  background-color: #f7fff5;
}

 
.App .letter
{
    display: inline-block;   
    border-radius: 50%;    
    padding: 3px;
    width: 24px;
    height: 24px;
    text-align: center;
    background: #3498DB;
    color: #fff;
}


.App .hide_view
{
  display: none!important;
}

.App .text_center
{
  text-align: center!important;
}

.App .text_bold
{
  font-weight: bold;
}

.App .text_link
{
   cursor: pointer;
}

.App .text_link_red
{
   cursor: pointer;
   color: #d00;
}

.App .number
{
  text-align: right!important;
}

.App .link
{
  text-decoration: none;
  color: #000;
  cursor: pointer;
}

.App .printView
{
   display: none;
}


.App .p1_1
{
  width: 8%;
  display: inline-block;
  padding: 1%;
  vertical-align: middle;
}
.App .p1_2
{
  width: 18.7%;
  display: inline-block;
  padding: 1%;
  vertical-align: middle;
}
.App .p1_3
{
  width: 28.7%;
  display: inline-block;
  padding: 1%;
  vertical-align: middle;
}
.App .p1_4
{
  width: 38.7%;
  display: inline-block;
  padding: 1%;
  vertical-align: middle;
}
.App .p1_5
{
  width: 48.7%;
  display: inline-block;
  padding: 1%;
  vertical-align: middle;
}
.App .p1_6
{
  width: 58.7%;
  display: inline-block;
  padding: 1%;
  vertical-align: middle;
}

.App .padding-2
{
  padding: 2%;
}
.App .padding-bottom-2
{
  padding-bottom: 2%;
}
.App p
{
  line-height: 1.5rem; /*normal*/
}

 


.App .buttonStandard {

	background-color:#21337a;
	border-radius:6px;
	border:1px solid #996814;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:16px;
	font-weight:bold;
	padding:15px 40px;
	text-decoration:none;
}
.App .buttonStandard:hover {
	background-color:#314cb4;
}
.App .buttonStandard:active {
	position:relative;
	top:1px;
}




.App .buttonForm {
	background:linear-gradient(to bottom, #172C65 5%, #324A8C 100%);
  background-color: #21337a;
	border-radius:6px;
	border:1px solid #324A8C;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:12px;
	font-weight:bold;
	padding:10px 30px;
	text-decoration:none; 

}
.App .buttonForm:hover {
	background:linear-gradient(to bottom, #172C65 5%, #324A8C 100%);
	background-color:#172C65;
}
.App .buttonForm:active {
	position:relative;
	top:1px;
}

.App .buttonFormRed {
	background-color: #21337a;
	border-radius:6px;
	border:1px solid #FF3333;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:12px;
	font-weight:bold;
	padding:10px 30px;
	text-decoration:none; 
   
}


.App .emptyResult
{
    text-align: center;
    padding: 50px;
    line-height: 1.6;
}

.App .emptyResult img
{
  width: 100px;
}

.App .buttonAbove
{
  text-align: right;
  margin-top: 10px;
  margin-bottom: 10px;
}




.App  .progressBarX
{  
  background-color: #eee;
  border: 1px solid #999; 
  height: 20px;
  line-height: normal;
  padding: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.App  .progressBarX .progressLineX
{
  width: 0.5%;
  height: 100%;
  display: inline-block;
  background-color: #0d0; 
}


.fileLister
{
   width: 100%;   
   border: 0px!important;
}

.fileLister td
{
  border: 0px!important;
}

.fileLister td img
{
   width: 14px!important;
   position: relative;
   top: 2px;
   cursor: pointer;
}


.fileUploader
{
}

.fileUploader .dragAndDrop
{
    color:  #aaa;
    padding: 22px;
    font-size: 24px;
    background-color: #eee;
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
}

.fileUploader .buttonUploader
{
    text-align: center;
}

.fileUploader .buttonUploader input
{
  display: none;
}


.fileUploader .fileListSc 
{
  max-height: 150px;
  overflow-y: scroll;   
  margin-top: 5px;
  margin-bottom: 5px;
}

.fileUploader .fileList 
{
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
}

.fileUploader .fileList td
{
  padding: 2px; 
  border-bottom: 1px solid #ddd;
}

.fileUploader .fileList td img
{
  width: 20px;
}

.fileUploader .progressBar
{
  width: 100%;
  height: 10px;
  background-color: #eee;
  border: 1px solid #999;
  margin-top: 5px;
  margin-bottom: 5px;
}

.fileUploader .progressBar .progressLine
{
  height: 100%;
  background-color: #0d0;
}


.App .windowTextContent
{
  padding: 10px;
  background-color: #faf9eb; 
  font-size: 13px;
}

.App .simplyFileUploader  .fileUploader h3
{
  display: none;
}

.App .simplyFileUploader  .fileUploader .fileListSc
{
  background-color: #fff;
  font-size: 12px;
}

.App .wniosekMsg
{
  text-align: center;
  background-color: #389c32;
  padding: 10px;
  font-size: 13px;
  line-height: 1.9;
  color: #fff;
}


.App .buttonNewItem {
	background:linear-gradient(to bottom, #127400 5%, #3e8332 100%);
	background-color:#127400;
	border-radius:6px;
	border:1px solid #3e8332;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:12px;
	font-weight:bold;
	padding:10px 30px;
	text-decoration:none; 
   
}
.App .buttonNewItem:hover {
	background:linear-gradient(to bottom, #127400 5%, #3e8332 100%);
	background-color:#127400;
}
.App .buttonNewItem:active {
	position:relative;
	top:1px;
}


.App .buttonAlert
{
  background:linear-gradient(to bottom, #dd0000 5%, #cc0000 100%);
  background-color:#DD0000!important;
  border: 1px solid #cc0000;
}

.App .buttonAlert:hover
{
  background:linear-gradient(to bottom, #dd0000 5%, #aa0000 100%);
  background-color:#DD0000!important;
  border: 1px solid #cc0000;
}


.App input[type=text], .App input[type=password], .App select, .App textarea
{
    padding: 8px;
    border: 1px solid #ddd;
    display: inline-block;
}

.App .react-datepicker-wrapper
{
 
}


.App .dialog_alert
{
  position:fixed;
  top:20%;
  left:35%;
  width: 30%;
  max-height: 550px;
  
  background-color: #fff9cf;
  border: 4px solid #21337a;   
  z-index: 2222;

  -webkit-box-shadow: 0px 8px 39px -11px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0px 8px 39px -11px rgba(66, 68, 90, 1);
  box-shadow: 0px 8px 39px -11px rgba(66, 68, 90, 1);

  border-radius: 5px;

}

.App .dialog_alert .dialog_title
{
   background-color: #21337a ;
   color: #fff;
   padding: 5px;
   font-size: 15px;
}

.App .dialog_alert .dialog_container
{
    padding: 10px;
    text-align: center;
}

.App .dialog_alert .dialog_container .dialog_query
{
  padding: 10px;
  text-align: center;
  color: #000 ;
}


.App .messanger
{
   
}

.App .messanger .startIco
{
  width: 20px; /*14px*/
}

.App .messanger .dialogMessanger
{
    z-index: 20;
    position: fixed;
    top: 10%;
    left: 15%;
    bottom: 10%;
    right:  15%;  
    background-color: #ddd;
    border: 1px solid #aaa;
    cursor:default;

    -webkit-box-shadow: -1px 3px 19px -11px rgba(66, 68, 90, 1);
    -moz-box-shadow: -1px 3px 19px -11px rgba(66, 68, 90, 1);
    box-shadow: -1px 3px 19px -11px rgba(66, 68, 90, 1);

    
}
 

.App .messanger .dialogMessanger .persons
{
     width: 20%;
     height: 100%;
     float: left;
     background-color: #B5BECD;
     border-right: 1px solid #ddd;
     overflow-y: auto;
}

.App .messanger .dialogMessanger .persons ul
{
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

.App .messanger .dialogMessanger .persons input
{
     width: 90%;
     padding: 5%;
     background-color: #8793a7;
}

.App .messanger .dialogMessanger .persons ul li
{
   background-color: #8793a7; 
   margin-top: 1px;
   margin-bottom: 1px; 
   padding-top: 10px;  
   padding-bottom: 10px;
   padding-left: 10px;
   cursor: pointer;
   color: #fff;
   font-size: 14px;
   line-height: 1.9;
   overflow-x: hidden;
}

.App .messanger .dialogMessanger .persons ul li:hover, .App .messanger .dialogMessanger .persons ul li.selected
{
  background-color: #738096; 
}

.App .messanger .dialogMessanger .tabs
{     
    background-color: #eee;    
    margin-left: 20%;     
    height: 100%;  

    background-color: #B5BECD; 
}

.App .messanger .dialogMessanger .tabs .chat
{
    height: 55%;   
    background-color: #fff;    
    
}

.App .messanger .dialogMessanger .tabs .chat .title
{
   background:linear-gradient(to bottom, #172C65 5%, #324A8C 100%);
	 background-color:#172C65;

   color: #fff;

   height: 0%;
   min-height: 15px;
   vertical-align: middle;
   font-size: 14px;
   padding: 15px;
}

.App .messanger .dialogMessanger .tabs .chat .messages
{    
   overflow-y: auto;
   padding: 5%;
   height: 70%;
}

.App .messanger .dialogMessanger .tabs .send
{
   background-color: #B5BECD; 
   border-top: 1px solid #ddd;
   height: 30%; 
}

.App .messanger .dialogMessanger .tabs .send input
{
  padding: 1%!important;
  margin: 1%!important;
  width: 95%!important; 

}

.App .messanger .dialogMessanger .tabs .send textarea
{
  padding: 1%!important;
  margin: 1%!important;
  width: 95%!important;
  height: 30%!important;
}

.App .messanger .dialogMessanger .tabs .send .buttonM
{
  text-align: right;
}

.App .messanger .dialogMessanger .tabs .send .buttonM img { width: 12px}
 
.App .messanger .dialogMessanger .msgItem
{    
     margin-bottom: 30px;

     background-color: #fff;    
     border: 1px solid #ddd; 

     font-size: 14px;

 
}




.App .messanger .dialogMessanger .msgItem .msgtitle
{
   background-color: #738096; 
   color: #fff;
   padding: 10px;
}

.App .messanger .dialogMessanger .msgItem .msgTxt
{
  padding: 5px;
  line-height: 1.9;
}

.App .messanger .dialogMessanger .attach
{
    padding-left: 10px;
}

.App .messanger .dialogMessanger .attach .attach_item
{
  display: inline-block; margin: 5px;
}



.compCalendar
{
  margin: 0px;
  min-width: 700px;   
}

.compCalendar .cellWidth
{
  width: 14%;
  display: inline-block;
  border: 1px dotted #ccc;
  padding: 0%;
}

.compCalendar .cellWidth:hover
{
  background-color: #eee;
}

.compCalendar .celHeightN
{
  height: 150px;  
  overflow:hidden;
  border-radius: 10px;
}

.compCalendar .dayNum
{
    text-align: right;
    font-weight: bold;
    font-size: 16px;
    padding-right: 2px;
    padding-top: 2px;
    border-bottom: 1px dotted #000;
    background-color: #e1e1e1;
}

.compCalendar .cellWidth.cellBold:hover
{
  pointer-events: none;
}

.compCalendar .dayContent
{
    text-align: left;    
}

.compCalendar .cellBold
{
  background-color: #21337a; 
  font-size: 14px; 
  color: #fff;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  
}

.compCalendar .dayContent .task
{
   margin-top: 2px;
   margin-bottom: 2px;
   padding: 3px 1px 3px 1px;
   background-color: #e78727;
   color: #fff;
   cursor: pointer;
   padding:6px;
   border-radius: 5px;
}

.compCalendar .dayContent .task:hover
{
  background-color: #d67a1e;
}

.compCalendar .dayContent .task .hour
{
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 2px;
}

.compCalendar .compControll
{
  margin-top: 10px;
  margin-bottom: 10px;
}

.compCalendar .compControllLeft
{
  float: right;
}

.compCalendar .compControllLeft .btnD
{
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}


.compCalendar .cellHor
{
  width: 100%; 
  margin: 1px;
  text-align: left;
}

.compCalendar .cellHor .dayNum
{
  width:4%;
  padding: 1%;
  display: inline-block;
  background-color: #3F506C;  
  color: #fff;
  text-align: center;
  vertical-align: middle;
}


.compCalendar .cellHor .dayT
{
  width:10%;
  padding: 1%;
  display: inline-block;
  background-color: #ccc;  
  text-align: center;
  vertical-align: middle;
  margin-left: 1%;
}
.compCalendar .cellHor .dayContent1
{
  width: 75%;  
  padding: 1%;
 
  display: inline-block;
  border: 1px dotted #aaa;  
  vertical-align: middle;
}

.compCalendar .cellHor .dayContent1 .task2
{
  margin-top: 2px;
  margin-bottom: 2px;
  padding: 3px 1px 3px 1px;
  background-color: #e78727;
  color: #fff;
  cursor: pointer;
}


.formSimplyField
{
  margin: 15px;
}



/*-------------------------------------------------------*/
/*-------------------------------------------------------*/

@media screen and (min-width:1401px) and (max-width:2000px){

  .App .dashForm .FormField 
  {
  
    /*width:46% !important; 
    display: inline-block !important;*/
  } 
}

@media screen and (max-width:1400px) {

  .App .field300
  {
    width:95% !important; 
  }
  .App .dashForm .FormField 
  {
    width:95% !important; 
  } 
}


@media screen and (max-width:800px) {




.App .dashboard .d_menu_tab .list .table_content
{
  overflow-x: scroll;
}
.App .messanger .startIco
{
  width: 14px; /*14px*/
}

.App .dashboard 
{
  margin-top: 10px;
}
.App .dashboard .d_menu
{
  display: block;
  width: auto;
  float: none;
  margin: 2%;
}

.App .dashboard .d_menu_tab
{
  display: block;
  width: auto;
  float: none;
  margin: 0;
}

  .App .simplyForm .formField50
{
   display:  block;
   width: auto;
   margin: 2%;
}

  .App .loginWrapper
{
   margin-left: 1%;
   margin-right: 1%;
   margin-top: 5px;
}

.App .loginWrapper .register{ display: block; width: 96%; }
.App .loginWrapper .login{ display: block;  width:  96%;}

   .App .header .menu
   {   
    padding-left: 1%;
    padding-right: 1%; 
   }
  
  
   .App .header .menu .item
   {
       display: inline-flex;  
       width: 50%; /*45%*/    
       justify-content: center;      
   }

   .App .simplyForm{padding: 5px; width:97%;}
   
   .App .header .bar{justify-content: center!important;}

   .App .header .bar div{
     font-size:14px!important;
     margin-left: 10px!important;
   }

   .App .header .bar .phone{display: none!important;}
   .App .header .bar .mail{display: none!important;}

  /* .App .header .bar .theme-switcher-menu{font-size: 14px;}
   .App .header .bar .contrast-button{font-size: 14px;}
   .App .header .bar .font-size-buttons .font-size-plus{font-size: 18px;}
   .App .header .bar .font-size-buttons .font-size-minus{font-size: 18px;}*/

   .App .simplyForm .formField .buttonForm{width: 96%;}
   .App .header .bar .submenu.inline-submenu-lg{width: 200px;}

   .App .container .training .items{text-align: center;}
   .App .container .training .items .item{display: block;}
   .App .p1_4{width: 100%;padding: 0!important;}
   .App .border-left-right{
     border-right: none;
     border-left: none;
   }

   .App .window{
    width: 80%!important;
    left:6%!important;

   }
   .App .window_form{
    border:10px solid #eee; 
    height: 100%;
    overflow: scroll;
   }

   .App .dialog_alert{
    width:60%!important;
    left:20%!important;
   }

   .App .buttonAbove{text-align: center!important;}
   .App .buttonAbove .buttonNewItem{width: 85%!important;text-align: center!important;}

   .App .container .training .items .item .info .dates {
    display: flex;
    justify-content: center;
   }

   .App .container .training .items .item .info .price {
    padding: 1%;
    font-size: 16px;
    text-align: center;
   }

   .App .dashboard .d_menu_tab .list .search_bar .filter .filter_label{display: block!important;}
   .App .dashboard .d_menu_tab .list .search_bar .filterButton {text-align: center!important;}

   .App .dashboard .d_menu_tab .list .search_bar {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
   }

   .App .field500{width: 95%!important;}

   .App .container .training .items .item .info p{
     margin-top: 30px;
     margin-bottom: 40px;
   }

   .App .container .training .details h1{font-size: 16px}

   .App.AppDashboard .fs-4
   {
     display: flex!important;
     font-size: 13px!important;
   }
   .nav-pills .nav-link.fs-4
   {
     display: flex!important;
     font-size: 13px!important;
   }
   .theme-switcher-menu .themes-menu {
    top: calc(14% - 0.4rem);  
   } 
   .sin_el .sin_q_act{background-color: cornflowerblue!important;} 

   .sin_el .sin_q_act:hover::before
   {
    background-image: none!important;
   }

   .App .messanger .dialogMessanger
   {
     left: 2%!important;
     right: 2%!important;
     border-radius: .25rem!important;
   }
   .App .messanger .dialogMessanger .persons
   {
    width: 35%!important;
   }
   .App .messanger .dialogMessanger .persons ul li
   {
    font-size: 12px!important;
   }
   .App .messanger .dialogMessanger .tabs .send input {
    width: 75%!important;
    font-size: 12px!important;
   }
   .App .messanger .dialogMessanger .tabs .send textarea{
    width: 75%!important;
    font-size: 12px!important;
   }
   .submenu .submenu-item
   {
    padding: 5px;
    font-size: 12px;
   }

   .submenu .submenu-item .value
   {
    color: #000;
   }   

   .App.AppDashboard .me-md-auto {
    margin-right: auto !important;
  }
   .App .container .training{
    padding-left: 10px;
    padding-right: 10px;
   }

   .main-content{
    margin:0!important;
    padding: 0 5px!important;
   }

   .d_menu_tab .main-content .content input
   {
      width:96%;
   }

   .d_menu_tab .main-content .table_content .table td:nth-child(1) {
    display:none;
   }

   .d_menu_tab .main-content .content .buttonForm
  {
    margin-top: 10px;
  }

   .App .container .training .details .detailsButton {
    float: none;
   }

   .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    line-height: 24px!important;
   }

   .App .buttonStandard{
    display: block;
    text-align: center;
   }

   .table-content-boot{
    padding: 0px!important;
    box-shadow: 0 0px 0px 0 rgba(0,0,0,.14),0 0px 0px 0px rgba(0,0,0,.2),0 0px 0px 0 rgba(0,0,0,.12)!important;
   }

   .App.AppLogin .new_btn{width: 100%!important;}
   .App.AppLogin .exist_btn{width: 100%!important;}

   .App .header .bar .brightness-button
   {
       margin-left: 0px;
   }

   .App .header .menu .item.img { display: none;}
   .App .header .menu .item img { display: flex;}

   .App .header .menu .item_padding
   {
      padding:  5px;
   }


   .App .header .imageSplit
   {
    display: none;
   }


   .App .header .bar{
     position: relative;
     width: auto;
   }

   .App .header .menu{
    margin-top: 10px;
  }


   .App .header .bar .phone
   {
      display: block;
      padding: 5px;
      width: auto;
      text-align: left;
   }
   
   .App .header .bar .mail
   {
       display: block;
       padding: 5px;
       width: auto;
       text-align: left;
   }
   
   .App .header .bar .user
   {
      display: flex; 
      padding: 2px;
      width: auto;
      text-align: left;
      /*margin-top: 5px;*/ 
      justify-content: center!important;
      font-size: 16px;
      flex-wrap: wrap;
      margin-left: 10px!important;
   }

   .App .header .bar .font-size-plus
   {
    margin-right: 0!important;
   }

  .App .footer
   {  
   position:relative;
   width: auto;
   }

  

    .App .p1_3
    {
      width: 98%;
      padding: 0px;
    }

   .App .padding-2
   {
       padding: 1%;
   }

   
   

    .App .container .training .searchEngine
    {
        text-align: center;
        padding: 10px;
    }

    .App .container .training .searchEngine input
    {
      padding: 10px;
      width: 90%;
      margin-top: 10px;
      margin-bottom: 10px;
    }



}



.simply_messanger
{
  width: 90%;
  text-align: left;
  padding: 10px;
}

.simply_messanger input, .simply_messanger textarea
{
  width: 100%;
}

.simply_messanger textarea
{
  height: 200px;
}

/*-------------------------------------------------------*/
/*-------------------------------------------------------*/
/*-------------------------------------------------------*/