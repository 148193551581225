
:root{
--form-floating-height:            add(3.5rem, $input-height-border);
--form-floating-line-height:       1.25;
--form-floating-padding-x:         $input-padding-x;
--form-floating-padding-y:         1rem;
--form-floating-input-padding-t:   1.625rem;
--form-floating-input-padding-b:   .625rem;
--form-floating-label-opacity:     .65;
--form-floating-label-transform:   scale(.85) translateY(-.5rem) translateX(.15rem);
--form-floating-transition:        opacity .1s ease-in-out, transform .1s ease-in-out;
--bs-gutter-y: 1rem;
--bs-gutter-x: 1rem;
}



.filter_active
{
    color: #aaa !important;
}

.items .location a:hover
{
    color:#aaa !important;
} 
 
.table-content-boot {
    padding: 10px;
    margin-top: 5px;
    -webkit-box-shadow: 10px 10px 17px -13px rgba(0,0,0,0.75);
    border: 0;
    margin-bottom: 12px;
    margin-top: 10px;
    border-radius: 6px;
    color: #333;
    background: #fff;
    width: 100%;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
  }

  .table-boot {
    --bs-table-bg: transparent;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: #495057;
    --bs-table-striped-bg: #f8f9fa;
    --bs-table-active-color: #495057;
    --bs-table-active-bg: rgba(0,0,0,.1);
    --bs-table-hover-color: #495057;
    --bs-table-hover-bg: rgba(0,0,0,.038);
    border-color: #dee2e6;
    color: #495057;
    margin-bottom: 1rem;
    vertical-align: top;
    width: 100%;
    border-collapse: collapse;
    caption-side: bottom;    
  }

  .table-boot > thead {
    vertical-align: bottom;
    text-align: left;
    font-size: 0.8rem;    
    border-radius: 2px;
  }



  .table-content-boot-rap {
    padding: 10px;
    margin-top: 5px;   
    border: 1px solid #000;
    margin-bottom: 12px;
    margin-top: 10px;
    border-radius: 6px;
    color: #333;
    background: #fff;
    width: 98%;
    cursor: default;
  }

  .table-boot-rap {
    --bs-table-bg: transparent;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: #495057;
    --bs-table-striped-bg: #f8f9fa;
    --bs-table-active-color: #495057;
    --bs-table-active-bg: rgba(0,0,0,.1);
    --bs-table-hover-color: #495057;
    --bs-table-hover-bg: rgba(0,0,0,.038);
    border-color: #dee2e6;
    color: #495057;
    margin-bottom: 1rem;
    vertical-align: top;
    width: 98%; 
    border-collapse: collapse;
    caption-side: bottom;    
  }

  .table-boot-rap > thead {
    vertical-align: bottom;
    text-align: left;
    font-size: 0.8rem;    
    border-radius: 2px;
  }

  .table-dark {
    --bs-table-bg: #222e3c;
    --bs-table-striped-bg: #2c3034;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #373b3e;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #323539;
    --bs-table-hover-color: #fff;
    border-color: #373b3e;
    color: #fff;
    background-color: #343a40;
  }

 .rowLink-boot
 {
    display: table-row;
    vertical-align: inherit;    
 }

 .table-boot td, .table-boot th {
    padding: .35rem !important;
  }




  .table-dark {
    --bs-table-bg: #222e3c;
    --bs-table-striped-bg: #2c3034;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #373b3e;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #323539;
    --bs-table-hover-color: #fff;
    border-color: #373b3e;
    color: #fff;
    background-color: #343a40;
  }

 .rowLink-boot
 {
    display: table-row;
    vertical-align: inherit;    
 }

 .table-boot td, .table-boot th {
    padding: .35rem !important;
  }

.link{
    cursor: pointer;
}

.link:hover{
    color: blue;
}

.chatIcon
{
  display: inline-flex;
  margin-left: 10px;
  vertical-align: middle;
}
.chatIcon .startIco
{
  height: 20px;
  width: 30px;
}

.downloadIco
{
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.fileUploader .fileList .downloadIco img
{
  width: 45px;
}

.App .deleteIco
{
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.App .fileUploader .fileList .deleteIco img
{
  width: 45px;
}


.settings-body
{
  background: #fff;
  min-height: 100vh;
  overflow-x: hidden;
}

.col-md-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.py-5 {
  padding-bottom: 3rem !important;
  padding-top: 3rem !important;
}
.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.row {

  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);

}
.flex-wrap {
  flex-wrap: wrap !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: #fff !important;
}
.shadow {
  box-shadow: 0 .1rem .2rem rgba(0,0,0,.05) !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.overflow-x {
  overflow-x: auto !important;
}
.overflow-y {
  overflow-y: auto !important;
}
.rounded {
  border-radius: .2rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.cover {
  /*background-image: url('/app/view/image/logo-spkip-black.png');*/
  background-color: #222e3c;
  background-size: 250px 100px;
  background-repeat: no-repeat;
  background-position-x: 400px;
}

.media{
  display: flex;
}

.profile-head
{
  transform: translateY(5rem);
} 

.align-items-end
{
  align-items: flex-end !important;
}

.mr-3
{
  margin-right: 1rem !important;
}
.ml-2
{
  margin-left: 0.5rem !important;
}
.ml-3
{
  margin-left: 1rem !important;
}
.img-thumbnail {
  max-width: 100%;
  background:transparent;
  border:none;
  width: 130px;
}

.rounded {
  border-radius: .2rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.text-white
{
  opacity:1;
  color: #fff;
}
.media-body
{
  flex:1;
}

.bg-light {
  opacity: 1;
  background-color: #fff;
}
.text-center {
  text-align: center !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.d-flex {
  display: flex !important;
}


.App   .window 
{  
  position:fixed;
  top:3%;
  left:25%;
  width: 50%;
  background-color: #fff;
  border: 30px solid #414956; 
  

  z-index: 51;

  -webkit-box-shadow: 0px 8px 39px -11px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0px 8px 39px -11px rgba(66, 68, 90, 1);
  box-shadow: 0px 8px 39px -11px rgba(66, 68, 90, 1);
}

.App   .window_form
{
  border: 10px solid #eee;   
}

.App   .window_list
{
     border: 30px solid #B5BECD;
}


.App  .window .container
{
    background-color: #ffffff;     
    color: #000;   
    height: 650px; 
    /*overflow-y: scroll;*/
}

.App  .window_form .container
{
    /*overflow-y: auto;*/
}

.App  .window .container_form
{
  padding: 1px; 
}

.App .font-small
{
  font-size: 12px;
}

.App  .window .container .options
{
  margin: 20px;
  margin-left: 5px;
}

.App  .window .container ul
{
  padding: 0px;
  margin: 10px;
  list-style-type: none;
}

.App  .window .container ul li
{
  padding: 10px;
  background-color: #eee;
  margin-top: 1px;
  margin-bottom: 1px;
  font-size: 14px;
  cursor: pointer;
}

.App  .window .container ul li:hover
{
  background-color: #ccc;
}

.ui.menu .dropdown.item .menu{background-color: #0d6efd;}

.f-30{font-size:30px}
.f-25{font-size:25px}
.f-20{font-size:20px}
.f-18{font-size:18px}
.f-15{font-size:15px}

@media (min-width: 768px) {
  .me-md-auto {
    margin-right: auto !important;
  }
}



.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: .25rem;
}

a:hover {
  color: #0a58ca;
}

.nav-link {
  display: flex;
  align-items: center;
  padding: .5rem .5rem;
  color: #fff;
  text-decoration: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

.nav-pills .nav-link a{
  color: #fff;
}
.nav-pills .nav-link .active{
  color: #fff;
  background-color: #0d6efd;
}
.right.menu .item .active{
  color: #fff;
  background-color: #0d6efd;
  border-radius: 4px;
}

.ui.menu{font-family: 'Montserrat', sans-serif!important;}
.ui.menu .menu .item {color: #fff!important;} 
.ui.menu .menu .item a{color: #fff!important;}
.ui.menu .item .icon{color: #000;}
.ui.menu .item .setting.icon{color: #fff!important;}
.ui.dropdown .menu > .item .dropdown.icon{float:none!important;}

.c-w {
  display: flex;
  align-items: center;
  padding: .5rem .5rem;
  color: #fff!important;
  text-decoration: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

.c-w.active{
  color: #fff;
  background-color: #0d6efd;
  border-radius: 4px;
}

.ui.menu .menu .item a:hover{color:#1857b4;transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;}

.b-red{border-bottom: 3px solid rgba(255,255,255,0.15); border-color: #e30d1a;}




.nav-pills .nav-link {
  background: 0 0;
    background-color: rgba(0, 0, 0, 0);
  border: 0;
  border-radius: .25rem;
}
.color-w{color:#fff!important;}

.container header span{color: #fff;}
.container header{
  border-bottom: 3px solid rgba(255, 255, 255, 0.15);
  border-color: #e30d1a;
}


.back-header{
  background-color: #21337a!important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.06);
}


.col-lg-12 {
  flex: 0 0 auto;
  width: 100%;
}
.col-12 {
  flex: 0 0 auto;
  width: 100%;
}
.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col, .form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}
/*.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}*/
.form-control {
  display: block;
  width: 100%;
  /*height: calc(1.0em + .75rem + 2px);*/
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-group {
  margin-bottom: 1rem;
}
.sin_el .q_act_wrap {
  display: grid;
  grid-template-columns: repeat(5,1fr);
  grid-gap: 30px;
  margin-bottom: 50px;
}
.sin_el .q_act_wrap_one {
  display: grid;
  grid-template-columns: repeat(1,1fr);
  grid-gap: 30px;
  margin-bottom: 50px;
}
.sin_el .sin_q_act::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  -webkit-transition: .5s;
  transition: .5s;
  background-size: cover;
}
.sin_el .sin_q_act {
  background-color: #fff;
  border-radius: 10px;
  -webkit-transition: .5s;
  transition: .5s;
  padding: 1px 20px;
  position: relative;
  background-color: #21337a;
}
.sin_el .sin_q_act h4 {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 700;
  -webkit-transition: .5s;
  transition: .5s;
  color: #fff;
  margin-bottom: 8px;
}
.sin_el .sin_q_act h3 {
  font-size: 20px;
  line-height: 40px;
  font-weight: 900;
  color: #fff;
}
.sin_el .sin_q_act p {
  -webkit-transition: .3s;
  transition: .3s;
  font-size: 13px;
  font-weight: 400;
  color: #fff;
}

.sin_el .sin_q_act:nth-child(n):hover {
  background-color: #1857b4;
}

.sin_el .sin_q_act:nth-child(2) {
  background-color: #21337a;
}
.sin_el .sin_q_act:nth-child(3) {
  background-color: #21337a;
}
.sin_el .sin_q_act:nth-child(4) { 
  background-color: #21337a;
}
.sin_el .sin_q_act:nth-child(5) {
  background-color: #21337a;
}


.main-content {
  min-height: 68vh;
  transition: .5s;
  position: relative;
  background: #f5f6ff;
  margin: 10px 10px 10px;
  border-radius: 10px;
  padding: 15px 15px;
}

.theme-switcher-menu
{
  display: flex;
  justify-content: left;
  align-items: left;
  align-content: center;
  background-color: transparent;
  border: none;

  cursor: pointer;

}

.d-none{display:none}

.button-wrap
{
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.submenu
{
  list-style: none;
  margin: 0;
  padding: 0;
}

.submenu .submenu-item
{
  text-align: left;
  padding: 15px;
  color: #000;
}

.submenu-item:hover
{
  background-color: #e1e1e1;
}

.themeDark
{
  color: #fff !important;    
  background-color: #000 !important;
}

.submenu.inline-submenu-lg {
  background-color: #fff; 
  border: 1px solid #aaa;
  border-radius: 3px;
  max-width: 75rem;
  position: absolute;
  top: calc(100% + 5px);
  width: 30px;
  width: 250px;
  z-index:3333;
}
.submenu.inline-submenu-lg::before {
  content: "";
  display: block;
  height: 8px;
  left: 0;
  position: absolute;
  top: -8px;
  width: 100%;
}

.theme-switcher-menu .themes-menu {
  top: calc(10% - 0.4rem);  
}
.submenu li {
  width: auto;
}


@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

/*----------------Bootstrap CSS--------------------*/

.rounded {
  border-radius: .25rem !important;
}
.bg-white {
  background-color: #fff !important;
}



/*.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  background-color: rgb(255, 255, 255);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  box-sizing: border-box;
  color: rgb(33, 37, 41);
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  max-width: 100%;
  padding-left: 16px;
  padding-right: 16px
}*/

@media (min-width: 1400px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1320px;
  }
}
@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1140px;
  }
}
@media (min-width: 992px) {
  .container, .container-lg, .container-md, .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 768px) {
  .container, .container-md, .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 576px) {
  .container, .container-sm {
    /*max-width: 540px;*/
    max-width: 100%;
  }
}
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;

}

.nav-item .nav-link a {color: #fff!important;}

.gap-3 {
  gap: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}
.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}
.py-1 {
  padding-top: .3rem !important;
  padding-bottom: .3rem !important;
}
dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.text-center {
  text-align: center !important;
}
.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.p-3 {
  padding: 1rem !important;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.flex-column {
  flex-direction: column !important;
}

.rounded-circle {
  border-radius: 50% !important;
}
.mt-5 {
  margin-top: 3rem !important;
}
.mt-6 {
  margin-top: 4rem !important;
}
.mt-7 {
  margin-top: 5rem !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.text-black-50 {
  color: rgba(0,0,0,.5) !important;
}
.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.col-md-2 {
  flex: 0 0 auto;
  width: 15%!important;
}
.col-md-3 {
  flex: 0 0 auto;
  width: 25%!important;
}
.col-md-4 {
  flex: 0 0 auto;
  width: 35%!important;
}
.col-md-5 {
  flex: 0 0 auto;
  width: 41.666667%!important;
}
.col-md-6 {
  flex: 0 0 auto;
  width: 50%!important;
}
.col-md-7 {
  flex: 0 0 auto;
  width: 65%!important;
}
.col-md-8 {
  flex: 0 0 auto;
  width: 75%!important;
}
.col-md-11 {
  flex: 0 0 auto; 
  width: 95%!important;
}
.col-md-12 {
  flex: 0 0 auto; 
  width: 100%!important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.p-1 {
  padding: 0.2rem !important;
}
.mb-3 {
  margin-bottom: 1rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.mb-1 {
  margin-bottom: 0.2rem !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.text-right {
  text-align: right !important;
}
.h4, h4 {
  font-size: 1.5rem;
}
.mt-2 {
  margin-top: .5rem !important;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
}
.mt-3 {
  margin-top: 1rem !important;
}



.btn-primary {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd; 
}
.btn-primary:hover {
  color: #fff;
  background-color: #025ce2;
  border-color: #0257d5;
}
.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: .375rem .75rem;
  font-size: 1rem;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

/*.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
}
.row > * {
  max-width: 100%;
  padding-right: calc(var(1.5rem)/ 2);
  padding-left: calc(var(1.5rem)/ 2);
  margin-top: var(0);
  box-sizing: border-box;
}*/


/*-----------------------------------------------*/

.body-profile {
  background: #e1e1e1;
}

.labels{font-size:13px!important;display: inline-block;}

.form-control:focus {
  box-shadow: none;
  border-color: #BA68C8
}

.profile-button {
  background: rgb(99, 39, 120);
  box-shadow: none;
  border: none
}

.profile-button:hover {
  background: #682773
}

.profile-button:focus {
  background: #682773;
  box-shadow: none
}

.profile-button:active {
  background: #682773;
  box-shadow: none
}

.back:hover {
  color: #682773;
  cursor: pointer
}



.add-experience:hover {
  background: #BA68C8;
  color: #fff;
  cursor: pointer;
  border: solid 1px #BA68C8
}





.w100{width:97%}

.w-100{
  width:50%;
}
.w-80{
  width:80%;
}
.align-center
{
  text-align: center;
}

.info-wrap {
  margin: auto;
  margin-top: 20px;
  margin-bottom:120px;  
}

.bg-primary {
  background: #f2f2f2 !important;
}
.p-lg-5 {
  padding: 3rem !important;
}
.info-wrap .dbox {
  width: 100%;
  color: #000;
}
.align-items-start {
  -webkit-box-align: start !important;
  align-items: flex-start !important;
}


.info-wrap .dbox .icon {
  width: 150px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
}

.align-items-center {
  -webkit-box-align: center !important;
  align-items: center !important;
}
.justify-content-center {
  -webkit-box-pack: center !important;
  justify-content: center !important;
}

@media (min-width: 768px) {
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
}
@media (min-width: 768px) {
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
}
@media (min-width: 576px) {
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
}


@media (min-width: 576px) {  }

@media (min-width: 768px) {  }

@media (min-width: 992px) {  }

@media (min-width: 1200px) {  }


.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}



@import url('https://fonts.googleapis.com/css?family=Oswald');




.fl-left {
    float: left
}

.fl-right {
    float: right
}

h1 {
    text-transform: unset;
    font-weight: 900;
    border-left: 10px solid #fec500;
    padding-left: 10px;
    margin-bottom: 30px
}
h2 {
  text-transform: unset;
  font-weight: 800;
  border-left: 10px solid #fec500;
  padding-left: 10px;
  margin-bottom: 30px;
  font-size: 20px;
}
 
  
.row {

  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);

}


.row2 {
  overflow: hidden;
}
.App input[type="text"], .App input[type="password"], .App select, .App textarea {
  padding: 8px; 
  border: 1px solid #8888;
  display: inline-block;
}

.card {
    
    width: 100%; 
    background-color: #fff;
    color: #989898;
    margin-bottom: 10px;
    font-family: 'Montserrat', sans-serif;
    text-transform: unset;
    border-radius: 4px;
   /* position: relative;*/
}

.row_badania {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
}


.row_badania .article-badania {
  border: 1px solid #e1e1e1;
  margin-bottom: 10px; 
  width: 100%;
  background-color: #fff;
  color: #989898;
  margin-bottom: 10px;
  font-family: 'Montserrat', sans-serif;
  text-transform: unset;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
}
.row_badania .article-badania .card-badania-header{
  display: flex;
  align-content: center;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px;
  width: 100%;
}

.flex-direction-column{display: flex; flex-direction: column;}
.flex-direction-row{display: flex; flex-direction: row;}

.row_badania .article-badania .card-badania{
  display: flex;
  align-content: center;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 15px;
  width: calc(99% / 2);
  border-right: 2px dashed #dadde6;
}

.row_badania .article-badania .card-badania label{
  color: #000;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 2px;
}
.row_badania .article-badania .card-badania span{
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 10px;
}


.row_badania .article-badania .card-badania .card-badania-button-success{

  width: 180px;
  height: 30px;
  background-color: #4b962a;
  color: #fff;
  text-align: center;
  line-height: 30px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  border: 1px solid #376e1f;
}

.row_badania .article-badania .card-badania .card-badania-button-success a{ color: #fff;}
.row_badania .article-badania .card-badania .card-badania-button-danger  a{ color: #fff;}

.row_badania .article-badania .card-badania .card-badania-button-success:hover{ background-color: #4fa52a;}
.row_badania .article-badania .card-badania .card-badania-button-danger:hover{ background-color: rgb(255, 80, 80);}

.row_badania .article-badania .card-badania .card-badania-button-danger{

  width: 180px;
  height: 30px;
  background-color: #ed3a2d;
  color: #fff;
  text-align: center;
  line-height: 30px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  border: 1px solid #94381f;
}

@media screen and (max-width:800px) {
  .row_badania .article-badania .card-badania{width: 100%; padding: 5px;border: 1px dashed #dadde6}
  .row_badania .article-badania .card-badania span{font-size: 14px;}
  .row_badania .article-badania{flex-direction: column-reverse;}
}


.row_szkolenia {
  flex-direction: column;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
}

.card+.card {
    margin-left: 2%
}

.date {
    display: table-cell;
    width: 15%;
    position: relative;
    text-align: center;
    border-right: 2px dashed #dadde6
}

.date2 {
  border-left: 2px dashed #dadde6
}

.date:before,
.date:after {

    display: block;
    width: 30px;
    height: 30px;
    background-color: #DADDE6;
    position: absolute;
    top: -15px;
    right: -15px;
    z-index: 1;
    border-radius: 50%
}

.date:after {
    top: auto;
    bottom: -15px
}

.date time {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.date time span {
    display: block
}

.date time span:first-child {
    color: #2b2b2b;
    font-weight: 600;
    font-size: 100%
}

.date time span:last-child {
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 20px
}

.card-cont {
    display: table-cell;
    width: 55%;
    font-size: 85%;
    padding: 10px 10px 10px 20px
}

.card-cont h3 {
    color: #3C3C3C;
    font-size: 130%
}



.card-cont>div {
    display: table-row
}

.card-cont .even-date i,
.card-cont .even-info i,
.card-cont .even-date time,
.card-cont .even-info p {
    display: table;
}

.card-cont .even-date i,
.card-cont .even-info i {
    padding: 5% 5% 0 0
}

.card-cont .even-info p {
    padding: 30px 50px 0 0
}

.card-cont .even-date time span {
    display: block;    
}

.card-cont .even-date .even-info  span {
  line-height: 23px;
}

.card-cont .detailsButton a{

    text-decoration: none;
    width: 180px;
    height: 30px;
    background-color: #D8DDE0;
    color: #fff;
    text-align: center;
    line-height: 30px;
    border-radius: 2px;
    position: relative;
    right: 10px;
    bottom: 10px;
}

.card-cont .wniosekButton a
{
  text-decoration: none;
  padding: 10px;
  width: 180px;
  height: 30px;
  background-color: #D8DDE0;
  color: #fff;
  text-align: center;
  line-height: 30px;
  border-radius: 2px;
  position: relative;
  right: 10px;
  bottom: 10px;
}

.card-cont .detailsButton
{
  display: inline-flex;
}

.row:first-child .card:first-child .card-cont a {
    background-color: #037FDD
}

.row:last-child .card:last-child .card-cont a {
    background-color: #F8504C
}





@media screen and (max-width: 800px) {

    .ui.vertical.menu{margin-bottom: 30px!important;} 

    .card {
        display: inline-block;
        float: none;
        width: 100%;
        margin-bottom: 10px
    }
    .card+.card {
        margin-left: 0
    }
    .card-cont .even-date,
    .card-cont .even-info {
        font-size: 75%; 
    }

    .date{
      width: 100%!important;
      display: block!important ;
      border:none!important;
      margin-top: 10px;
    }
    .date time{
      position: relative!important;
      top:0;
      left: 0;
      transform: unset!important;
    }

    .card-cont{padding: 5px!important;}

    .card-cont .even-date, .card-cont .even-info
    {
      font-size: 16px;
    }
    .card-cont > div{display: block;}
  
    .submenu.inline-submenu-lg
    {
      width:200px
    }

    .sin_el .q_act_wrap
    {
     grid-template-columns: repeat(1,1fr);
     grid-gap: 10px;
     margin-bottom: 20px;
    }
    .sin_el .sin_q_act h4 {
      font-size: 15px;
      line-height: 20px;
      margin:0;
      font-weight: 900;
      color: #fff;
    }
    .sin_el .sin_q_act h3 {
      font-size: 15px;
      line-height: 20px;
      margin:0;
      font-weight: 900;
      color: #fff;
    }

    .compCalendar .dayContent .task
    {
      font-size: 12px;
    }

    .App .dashboard .d_menu_tab .list .sortby
    {
      display:flex;
      align-items: center;
    }
    .App .dashboard .d_menu_tab .list .pagger .pright
    {
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .App .field200{margin-bottom: 10px;}

    .App .dashboard .d_menu_tab .list .pagger .pright
    {
      display:flex;
      align-items: center;   
      width:100%; 
    }

    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
      color: #fff;
      background-color: #0d6efd;
    } 

   /* .date{
      border: none;
      width: 0%; 
      display: flex!important;
    }

    .date time{
      display: flex!important;
      position: relative!important;
      transform: unset!important;  
      top:0;
      left:0;  
      width: 100%;  
    }
    .date time span{
      display: flex!important;
    }*/

}





.rowTrain .card 
{
  border: 1px solid #e1e1e1;
  margin-bottom: 10px;
}
.rowTrain .card:hover
{
  background-color: #f2f2f2;
}
.rowFlex
{
  width:auto;
  display:flex;
}

 .info_shadow {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 102;
  cursor:default;
}

.info_succ
{
  padding: 5px 5px 5px 5px;
  text-align: center;
  background-color: green;
  color:#fff;
  margin-bottom: 5px;
  width: 100%;
  font-weight: 500;
}
.info_war
{
  padding: 5px 5px 5px 5px;
  text-align: center;
  background-color: #fec500;
  color:#000;
  margin-bottom: 5px;
  width: 100%;
  font-weight: 500;
}


.price2
{
  font-size: 18px;
  font-weight: bold;
  color: #192E68;
  padding: 5px;
}

.App .w-2{width:2%}
.App .w-96{width:95.8%}
.App .w-88{width:87.8%}
.App .w-10{width:9.8%}

.App.AppLogin .new_btn
{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 25px;
  margin: 10px;
  background: rgb(33,51,122);
  background: linear-gradient(90deg, rgba(33,51,122,1) 35%, rgba(44,67,160,1) 89%); 
  color: #fff;
  width: 40%;
  height: 30%;
  font-size: 22px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.06);
  font-weight: 300;
  line-height: 1.1em;
}
.App.AppLogin .exist_btn
{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center; 
  padding: 30px;
  margin: 10px;
  background: rgb(33,51,122);
background: linear-gradient(90deg, rgba(33,51,122,1) 35%, rgba(44,67,160,1) 89%); 
  color: #fff;
  width: 40%;
  height: 30%;
  font-size: 22px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.06);
  font-weight: 300;
  line-height: 1.1em;
}

.App.AppLogin .new_btn:hover
{
  background: rgb(33,51,122);
  background: linear-gradient(90deg, rgba(33,51,122,1) 4%, rgba(44,67,160,1) 35%); 
}
.App.AppLogin .exist_btn:hover
{
  background: rgb(33,51,122);
  background: linear-gradient(90deg, rgba(33,51,122,1) 4%, rgba(44,67,160,1) 35%); 
}





.App .modal {
 
  box-sizing: content-box!important;
  position: fixed; 
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;  
  scrollbar-width: none;
}

.App .modal-content
{
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #E1E6FF;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: .3rem;
  outline: 0;
}

.App .row + .row
{
  margin-top: 1rem; 
}

.App .modal-body
{
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;

}

.App .col-form-label
{
  margin-top: 0.5rem;
  padding-top: 0px; /*calc(.375rem + 1px);*/
  padding-bottom: calc(.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
  display: inline-block;
}

.App .modal-header
{
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #8888;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
}

.App .form-select
{
  display: block;
  width: 100%;
  padding: .375rem 2.25rem .375rem .75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  margin:0px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media (min-width: 576px) {
  .App .modal-dialog
  {
      max-width: 650px;
      margin: 1.75rem auto;
    }
}
.App .modal-dialog
{
  position: relative;
  width: auto;
  pointer-events: none;
}

.App .modal > :last-child
{
  margin-bottom: 0;
}
.g-3, .gy-3
{
  --bs-gutter-y: 1rem;
}
.g-3, .gx-3
{
  --bs-gutter-x: 1rem;
}

.App .col-auto
{
  flex: 0 0 auto;
  width: auto;
}


.App .row > *
{
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: auto;
  box-sizing: border-box;
}

.App .modal-footer
{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #8888;
  border-bottom-right-radius: .3rem;
  border-bottom-left-radius: .3rem;
}

.App .modal-footer > :not(:last-child)
{
  margin-right: .25rem;
}

.App .btn-primary
{
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.App .btn-secondary
{
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.ms-auto
{
  margin-left: auto !important;
}

@media (min-width: 768px) {
  .col-md-2
{
    flex: 0 0 auto;
    width: 16.66666667%;
  }
}

@media (min-width: 768px) {
  .col-md-3
{
    flex: 0 0 auto;
    width: 25%;
  }
}
@media (min-width: 768px) {
  .col-md-4
{
    flex: 0 0 auto;
    width: 33.33333333%;
  }
}
@media (min-width: 768px) {
  .col-md-12
{
    flex: 0 0 auto;
    width: 100%;
  }
}



